import { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { THEME_ENUM } from 'constants/theme.constant'
import { setMode } from 'store/theme/themeSlice'
import { CompanyContext } from 'contexts/CompanyContext'
import { stripHTML } from 'utils/stripHTML'
import getFileType, { getFileTypeImageVideo } from 'utils/fileType'
import getVideoDuration from 'utils/getVideoDuration'
import getFileSize from 'utils/getFileSize'
import { useTranslation } from 'react-i18next'
import capitalize from 'components/ui/utils/capitalize'
import { getImageDimensions } from 'utils/getImageWidthHeight'
import { checkIfIsIntegrated } from 'utils/checkIfIsIntegrated'
import { checkIfIsExpired } from 'utils/checkIfIsExpired'
import { getNetworkCharCountUnformatted } from 'components/layout/MagicEditor/OptionsBar'
import { getVideoDimensions } from 'utils/getVideoDimensions'
import { socialNetworksMediaProductTypes } from 'views/calendar/SocialNetworkSidePanel'

export const getNetworkValidationIndex = (network, media_product_type) => {
    if (socialNetworksMediaProductTypes[network]?.includes(media_product_type)) {
        return `${network}_${(media_product_type || "FEED")?.toLowerCase()}`;
    } else {
        return `${network}_${socialNetworksMediaProductTypes[network]?.[0]?.toLowerCase()}`;
    }
}

function usePostValidator() {
    
    const { companySocials } = useContext(CompanyContext);
    const [ validationConstants, setValidationConstants ] = useState({});
    const [ videoDurations, setVideoDurations ] = useState({});
    const [ fileSizes, setFileSizes ] = useState({});
    const [ imageDimensions, setImageDimensions ] = useState({});
    const { t } = useTranslation();

    const _getNetworkPostName = (network, media_product_type) => {
        return `${capitalize(network)}${media_product_type !== "FEED" ? ` ${capitalize(media_product_type?.toLowerCase())}` : ""}`
    }

    const _getVideoDuration = async (uri) => {
        if (videoDurations?.[uri]) {
            return videoDurations?.[uri];
        }
        const response = await getVideoDuration(uri);
        if (response) {
            let _new = videoDurations;
            _new[uri] = response;
            setVideoDurations(_new);
            return response;
        }
        return null;
    }

    const _getFileSize = async (uri) => {
        if (fileSizes?.[uri]) {
            return fileSizes?.[uri];
        }
        try {
            const response = await getFileSize(uri);
            if (response) {
                let _new = fileSizes;
                _new[uri] = response;
                setFileSizes(_new);
                return response;
            }
        } catch {}
        return null;
    }

    const _getImageDimensions = async (uri) => {
        if (imageDimensions?.[uri]) {
            return imageDimensions?.[uri];
        }
        const { width, height } = await getImageDimensions(uri);
        if (width && height) {
            let _new = imageDimensions;
            _new[uri] = { width, height };
            setImageDimensions(_new);
            return _new[uri];
        }
        return null;
    }

    const _getImageRatio = async (uri) => {
        const { width, height } = await _getImageDimensions(uri);
        return parseFloat(width / height).toFixed(2);
    }

    const validate = async (posts) => {
        var errors = await getErrors(posts);
        return errors?.length > 0 ? false : true;
    }

    const getFirstError = async (posts) => {
        const errors = await getErrors(posts);

        return errors?.length > 0 ? errors[0]?.error : null;
    }

    const getErrors = async (posts) => {
        let errors = [];

        for (let j = 0; j < posts?.length; j++) {

            let post = posts[j];
            post.media_urls = post?.media_urls || post?.assets?.map((asset) => asset?.image?.url || asset?.video?.url);
            post.description = post?.description || post?.text;
            const networkValidationIndex = getNetworkValidationIndex(post?.network, post?.preferences?.media_product_type);
            const constants = validationConstants?.[networkValidationIndex];

            if (constants) {

                // connected network
                if (constants?.connection?.required) {
                    const socialNetworkIntegrationObj = companySocials?.filter(e => e.type === post?.network)?.[0];
                    const isIntegrated = checkIfIsIntegrated(post?.network, companySocials);
                    const isExpired = isIntegrated && checkIfIsExpired(socialNetworkIntegrationObj);
                    if (!isIntegrated || isExpired) errors.push({ network: post?.network, error: t(`postErrors.connectionRequired`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }

                // empty post
                if (!constants?.description?.required && !constants?.media_urls?.required) {
                    if (!post?.description && !post?.media_urls?.length) errors.push({ network: post?.network, error: t(`postErrors.postEmpty`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }

                // title
                if (constants?.title?.required == true) {
                    if (!post?.title) errors.push({ network: post?.network, error: t(`postErrors.titleRequired`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }
                if (constants?.title?.max_length !== undefined) {
                    if (post?.title?.length > constants?.title?.max_length) errors.push({ network: post?.network, error: t(`postErrors.titleLength`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_length: constants?.title?.max_length }) });
                } 

                // board_id
                if (constants?.board_id?.required == true) {
                    if (!post?.preferences?.board_id) errors.push({ network: post?.network, error: t(`postErrors.boardRequired`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }

                // privacy_level
                if (constants?.privacy_level?.required == true) {
                    if (!post?.preferences?.privacy_level) errors.push({ network: post?.network, error: t(`postErrors.privacyLevel`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }

                // description
                if (constants?.description?.required == true) {
                    if (!stripHTML(post?.description)) errors.push({ network: post?.network, error: t(`postErrors.descriptionRequired`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }
                if (constants?.description?.max_length !== undefined) {
                    if (getNetworkCharCountUnformatted(post?.network, post?.description) > constants?.description?.max_length) errors.push({ network: post?.network, error: t(`postErrors.descriptionLength`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_length: constants?.description?.max_length }) });
                } 

                // media_urls
                if (constants?.media_urls?.required == true) {
                    if (!post?.media_urls?.length) errors.push({ network: post?.network, error: t(`postErrors.mediaRequired`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }
                if (constants?.media_urls?.allow_multiple_types == false) {
                    if (post?.media_urls?.filter(url => getFileType(url) == "image")?.length > 0 && post?.media_urls?.filter(url => getFileType(url) == "video")?.length > 0) errors.push({ network: post?.network, error: t(`postErrors.combineMediaTypes`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }
                if (constants?.media_urls?.max_length !== undefined) {
                    if (post?.media_urls?.length > constants?.media_urls?.max_length) errors.push({ network: post?.network, error: t(`postErrors.mediaLength`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_length: constants?.media_urls?.max_length }) });
                }
                if (constants?.media_urls?.max_images_length !== undefined) {
                    if (post?.media_urls?.filter(url => getFileType(url) == "image")?.length > constants?.media_urls?.max_images_length) errors.push({ network: post?.network, error: t(`postErrors.mediaImagesLength`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_length: constants?.media_urls?.max_images_length }) });
                }
                if (constants?.media_urls?.max_videos_length !== undefined) {
                    if (post?.media_urls?.filter(url => getFileType(url) == "video")?.length > constants?.media_urls?.max_videos_length) errors.push({ network: post?.network, error: t(`postErrors.mediaVideosLength`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_length: constants?.media_urls?.max_videos_length }) });
                }
                if (constants?.media_urls?.max_gifs_length !== undefined) {
                    if (post?.media_urls?.filter(url => getFileType(url) == "gif")?.length > constants?.media_urls?.max_gifs_length) errors.push({ network: post?.network, error: t(`postErrors.mediaGifsLength`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_length: constants?.media_urls?.max_gifs_length }) });
                }
                if (constants?.media_urls?.image_max_size_bytes !== undefined) {
                    for (let i = 0; i < post?.media_urls?.length; i++) {
                        if (getFileType(post?.media_urls[i]) == "image") {
                            if (await _getFileSize(post?.media_urls[i]) > constants?.media_urls?.image_max_size_bytes) errors.push({ network: post?.network, error: t(`postErrors.imageMaxFileSize`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_size: constants?.media_urls?.image_max_size_bytes / 1024 / 1024 }) });
                        }
                    }
                }
                if (constants?.media_urls?.video_max_size_bytes !== undefined) {
                    for (let i = 0; i < post?.media_urls?.length; i++) {
                        if (getFileType(post?.media_urls[i]) == "video") {
                            if (await _getFileSize(post?.media_urls[i]) > constants?.media_urls?.video_max_size_bytes) errors.push({ network: post?.network, error: t(`postErrors.videoMaxFileSize`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_size: constants?.media_urls?.video_max_size_bytes / 1024 / 1024 }) });
                        }
                    }
                }
                if (constants?.media_urls?.video_min_duration_seconds !== undefined) {
                    for (let i = 0; i < post?.media_urls?.length; i++) {
                        if (getFileType(post?.media_urls[i]) == "video") {
                            if (await _getVideoDuration(post?.media_urls[i]) < constants?.media_urls?.video_min_duration_seconds) errors.push({ network: post?.network, error: t(`postErrors.videoMinDuration`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_duration: constants?.media_urls?.video_min_duration_seconds }) });
                        }
                    }
                }
                if (constants?.media_urls?.video_max_duration_seconds !== undefined) {
                    for (let i = 0; i < post?.media_urls?.length; i++) {
                        if (getFileType(post?.media_urls[i]) == "video") {
                            if (await _getVideoDuration(post?.media_urls[i]) > constants?.media_urls?.video_max_duration_seconds) errors.push({ network: post?.network, error: t(`postErrors.videoMaxDuration`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), min_duration: constants?.media_urls?.video_max_duration_seconds }) });
                        }
                    }
                }
                if (constants?.media_urls?.same_ratio == true) {
                    let ratios = [];
                    for (let i = 0; i < post?.media_urls?.filter(url => getFileTypeImageVideo(url) == "image").length; i++) ratios.push(await _getImageRatio(post?.media_urls[i]));
                    if (ratios?.length && !ratios.every(v => v === ratios[0])) errors.push({ network: post?.network, error: t(`postErrors.sameRatio`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),}) });
                }
                if (constants?.media_urls?.video_max_width !== undefined || constants?.media_urls?.video_min_width !== undefined) {
                    for (let i = 0; i < post?.media_urls?.length; i++) {
                        if (getFileType(post?.media_urls[i]) == "video") {
                            const { width } = await getVideoDimensions(post?.media_urls[i]);
                            if (!!constants?.media_urls?.video_max_width && width > constants?.media_urls?.video_max_width) {
                                errors.push({ network: post?.network, error: t(`postErrors.videoMaxWidth`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), max_width: constants?.media_urls?.video_max_width }) });
                            }
                            if (!!constants?.media_urls?.video_min_width && width < constants?.media_urls?.video_min_width) {
                                errors.push({ network: post?.network, error: t(`postErrors.videoMinWidth`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type), min_width: constants?.media_urls?.video_min_width }) });
                            }
                        }
                    }
                }

                if (constants?.media_urls?.allowed_aspect_ratios?.length > 0) {
                    for (let i = 0; i < post?.media_urls?.length; i++) {

                        if (getFileTypeImageVideo(post?.media_urls[i]) === "image" || getFileTypeImageVideo(post?.media_urls[i]) === "video") {
                            const { width, height } = getFileTypeImageVideo(post?.media_urls[i]) === "image"
                                ? await _getImageDimensions(post?.media_urls[i])
                                : await getVideoDimensions(post?.media_urls[i]);

                            const aspectRatio = width / height;
                            if (networkValidationIndex == "instagram_reel") console.log(networkValidationIndex, aspectRatio);
                            const [minRatio, maxRatio] = constants?.media_urls?.allowed_aspect_ratios;
                            const isValidRatio = minRatio <= aspectRatio && aspectRatio <= maxRatio;
                
                            if (!isValidRatio) {
                                errors.push({
                                    network: post?.network,
                                    error: t(`postErrors.invalidAspectRatio`, { network: _getNetworkPostName(post?.network, post?.preferences?.media_product_type),})
                                });
                            }
                        }
                    }
                }
            }
        }
        
        return errors;
    }

    useEffect(() => {
        setValidationConstants(
            {
                medium_feed: {
                    connection: {
                        required: true
                    },
                    title: {
                        max_length: 100,
                        required: true
                    },
                    description: {
                        max_length: 10240000,
                        required: true
                    }
                },
                pinterest_feed: {
                    connection: {
                        required: true
                    },
                    title: {
                        max_length: 100,
                        required: true
                    },
                    board_id: {
                        required: true
                    },
                    description: {
                        max_length: 500
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 5,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 5,
                        required: true,
                        image_max_size_bytes: 32 * 1024 * 1024,
                        video_max_size_bytes: 2000 * 1024 * 1024,
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 15 * 60,
                        same_ratio: true
                    }
                },
                twitter_feed: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: !!companySocials?.filter(e => e.type == "twitter")?.[0]?.preferences?.verified ? 280 : 280
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 4,
                        max_videos_length: 1,
                        max_gifs_length: 1,
                        max_length: 4,
                        required: false,
                        image_max_size_bytes: 5 * 1024 * 1024,
                        video_max_size_bytes: 512 * 1024 * 1024,
                        video_min_duration_seconds: 0.5, 
                        video_max_duration_seconds: 140
                    }
                },
                facebook_feed: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 60000
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 10,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 10,
                        required: false,
                        image_max_size_bytes: 8 * 1024 * 1024,
                        video_max_size_bytes: 10 * 1024 * 1024 * 1024, // 10 GB
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 60 * 20 // 20 mins
                    }
                },
                facebook_reel: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 60000
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 0,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        image_max_size_bytes: 8 * 1024 * 1024,
                        video_max_size_bytes: 1 * 1024 * 1024 * 1024, // 1 GB
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 90, // 90 secs
                        video_max_width: 1920,
                        video_min_width: 540,
                        allowed_aspect_ratios: [0.5, 0.6]
                    }
                },
                facebook_story: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 0
                    },
                    media_urls: {
                        allow_multiple_types: true,
                        max_images_length: 1,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        image_max_size_bytes: 8 * 1024 * 1024,
                        video_max_size_bytes: 100 * 1024 * 1024,
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 60, // 60 secs
                        video_max_width: 1920,
                        video_min_width: 540,
                        allowed_aspect_ratios: [0.5, 0.6]
                    }
                },
                instagram_feed: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 2200
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 10,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 10,
                        required: true,
                        image_max_size_bytes: 8 * 1024 * 1024,
                        video_max_size_bytes: 10 * 1024 * 1024 * 1024, // 10GB
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 60 * 15, // 15 MINS
                        video_max_width: 1920,
                        allowed_aspect_ratios: [0.562, 1.91]
                    }
                },
                instagram_reel: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 2200
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 0,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        image_max_size_bytes: undefined,
                        video_max_size_bytes: 1024 * 1024 * 1024,
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 15 * 60, // 15 MINS
                        video_max_width: 1920,
                        video_min_width: 720,
                        allowed_aspect_ratios: [0.5, 1.91]
                    }
                },
                instagram_story: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 0
                    },
                    media_urls: {
                        allow_multiple_types: true,
                        max_images_length: 1,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        image_max_size_bytes: 8 * 1024 * 1024,
                        video_max_size_bytes: 100 * 1024 * 1024,
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 60, // 60 secs
                        video_max_width: 1920,
                        video_min_width: 540,
                        allowed_aspect_ratios: [0.5, 0.6]
                    }
                },
                tiktok_feed: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 2200,
                        required: false
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 0,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        image_max_size_bytes: undefined,
                        video_max_size_bytes: 4 * 1024 * 1024 * 1024,
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: companySocials?.filter(e => e.type == "tiktok")?.[0]?.video_duration || 10 * 60
                    },
                    privacy_level: {
                        required: true
                    }
                },
                linkedin_feed: {
                    connection: {
                        required: true
                    },
                    description: {
                        max_length: 3000,
                        required: true
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 9,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 9,
                        required: false,
                        image_max_size_bytes: 8 * 1024 * 1024,
                        video_max_size_bytes: 200 * 1024 * 1024,
                        video_min_duration_seconds: 3, 
                        video_max_duration_seconds: 10 * 60
                    }
                },
                youtube_feed: {
                    connection: {
                        required: true
                    },
                    title: {
                        max_length: 100,
                        required: true
                    },
                    description: {
                        max_length: 5000,
                        required: false
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 0,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        image_max_size_bytes: undefined,
                        video_max_size_bytes: 128 * 1024 * 1024 * 1024, // 128GB
                        video_min_duration_seconds: 1,
                        video_max_duration_seconds: 12 * 60 * 60, // 12 hours
                        video_max_width: 7680, // 8K
                        video_min_width: 426, // 240p
                        allowed_aspect_ratios: [0.3333, 2.3922] // between 1:3 and 21.9:9
                    }
                },
                youtube_short: {
                    connection: {
                        required: true
                    },
                    title: {
                        max_length: 100,
                        required: false
                    },
                    description: {
                        max_length: 5000,
                        required: false
                    },
                    media_urls: {
                        allow_multiple_types: false,
                        max_images_length: 0,
                        max_videos_length: 1,
                        max_gifs_length: 0,
                        max_length: 1,
                        required: true,
                        video_max_size_bytes: 256 * 1024 * 1024, // 256MB
                        video_min_duration_seconds: 1,
                        video_max_duration_seconds: 60, // 60 seconds
                        video_max_width: 1920,
                        video_min_width: 600,
                        allowed_aspect_ratios: [0.5, 0.625] // Must be 9:16 (aprox: check this)
                    }
                }
            }
        )
    }, [companySocials]);


    return { validate, getErrors, validationConstants, getFirstError }
}

export default usePostValidator
