import Popover from '@idui/react-popover';
import { Button, Tooltip } from 'components/ui'
import useUniqueId from 'components/ui/hooks/useUniqueId';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TbCircleArrowUpFilled, TbSquareArrowUpFilled, TbSquareRoundedArrowUp, TbSquareRoundedArrowUpFilled } from 'react-icons/tb'
import { useOnClickOutside } from 'usehooks-ts';
import useDarkMode from 'utils/hooks/useDarkMode';
import PlusModal from './PlusModal';
import { ModalContext } from 'contexts/ModalContext';
import { BsStars } from 'react-icons/bs';

export default function PlusBadge({
  className = "",
  wrapperClassName = "",
  feature = "",
  placement = "top"
}) {

  const { openModal } = useContext(ModalContext);
  const [ isDark ] = useDarkMode();

  const handleOnClick = (e) => {
    openModal(<PlusModal />, 650, true, true, "relative !overflow-visible !overflow-y-visible !p-0 !bg-transparent")
    e?.stopPropagation();
  }

  return (
    <>
      <Popover
        placement={placement}
        arrowColor={isDark ? 'black' : 'white'}
        className={`max-w-[300px] !border-0 flex flex-col dark:bg-black dark:text-gray-50 bg-white drop-shadow-xl !p-0 text-gray-900`}
        content={
          <div  
            onClick={(e) => e.stopPropagation()}
            className='divide-y divide-gray-100 dark:divide-gray-700'
          >
            <div className='p-2.5 flex items-start gap-2'>
              <TbSquareRoundedArrowUp 
                className='text-pink-500 text-2xl shrink-0 mt-1'
              />
              <span className='text-sm'>
                <b>
                  Upgrade your account to Followr&nbsp;
                  <span className='plus text-ai'>Plus</span>&nbsp; 
                  <BsStars className='text-pink-500 inline-block -mt-1 mr-1' />
                  to unlock this feature.
                </b>
                <span>&nbsp;Get <i>{feature}</i> and more!</span>
              </span>
            </div>
            <div className='p-2.5'>
              <Button
                variant="ai"
                size="sm"
                className="w-full !rounded-xl flex items-center justify-center gap-1"
                onClick={handleOnClick}
              >
                Get Followr 
                <span className='plus'>Plus</span>
                <BsStars />
              </Button>
            </div>
          </div>
        }
      >
        <div className={`${wrapperClassName}`}>
          <TbSquareRoundedArrowUpFilled
            onClick={handleOnClick}
            className={`transition-all text-pink-500 hover:text-pink-600 text-2xl cursor-pointer ${className}`} 
          />
        </div>
      </Popover>
    </>
  )
}
