import { YOUTUBE_ENABLED } from "components/ui/utils/constant";
import { Trans } from "react-i18next";
import { BsStars } from "react-icons/bs";
import { LuFileImage, LuImageOff, LuImagePlus } from "react-icons/lu";
import { TbDatabaseStar, TbGif, TbSettings } from "react-icons/tb";
import { isMobile } from "utils/isMobile";
import AIImages from "views/company_settings/components/AIImages";
import CreativeTemplates from "views/company_settings/components/CreativeTemplates";

const { useState, useEffect } = require("react");
const { communicationStyleOptions } = require("views/onboarding/StepCompanyCommunicationStyle");

export const ctaOptions = [
    { value: "Download now", name: <Trans i18nKey={`askAITemplate.downloadnow`}>Download now</Trans>, emoji: `📲` },
    { value: "Link to website", name: <Trans i18nKey={`askAITemplate.linktowebsite`}>Link to website</Trans>, emoji: `🌐` },
    { value: "Like post", name: <Trans i18nKey={`askAITemplate.likepost`}>Like post</Trans>, emoji: `❤️` },
    { value: "Comment post", name: <Trans i18nKey={`askAITemplate.commentpost`}>Comment post</Trans>, emoji: `💬` },
    { value: "Tag a friend", name: <Trans i18nKey={`askAITemplate.tagafriend`}>Tag a friend</Trans>, emoji: `🏷` },
];

export const formatOptions = [
    { value: "Discounts/Sales", name: <Trans i18nKey={`askAITemplate.discountsSales`}>Discounts/Sales</Trans> },
    { value: "How-to Guides", name: <Trans i18nKey={`askAITemplate.howtoGuides`}>How-to Guides</Trans> },
    { value: "Customer Testimonials", name: <Trans i18nKey={`askAITemplate.customerTestimonials`}>Customer Testimonials</Trans> },
    { value: "Breaking News", name: <Trans i18nKey={`askAITemplate.breakingNews`}>Breaking News</Trans> },
    { value: "Product Spotlights", name: <Trans i18nKey={`askAITemplate.productSpotlights`}>Product Spotlights</Trans> },
    { value: "Interactive Polls", name: <Trans i18nKey={`askAITemplate.interactivePolls`}>Interactive Polls</Trans> },
    { value: "Inspirational Quotes", name: <Trans i18nKey={`askAITemplate.inspirationalQuotes`}>Inspirational Quotes</Trans> },
    { value: "User-Generated Content", name: <Trans i18nKey={`askAITemplate.userGeneratedContent`}>User-Generated Content</Trans> },
    { value: "Industry Insights", name: <Trans i18nKey={`askAITemplate.industryInsights`}>Industry Insights</Trans> },
    { value: "Memes and Humor", name: <Trans i18nKey={`askAITemplate.memesandHumor`}>Memes and Humor</Trans> },
    { value: "Throwback Thursdays", name: <Trans i18nKey={`askAITemplate.throwbackThursdays`}>Throwback Thursdays</Trans> },
    { value: "Seasonal Promotions", name: <Trans i18nKey={`askAITemplate.seasonalPromotions`}>Seasonal Promotions</Trans> },
    { value: "Influencer Collaborations", name: <Trans i18nKey={`askAITemplate.influencerCollaborations`}>Influencer Collaborations</Trans> },
    { value: "Contest/Giveaway Announcements", name: <Trans i18nKey={`askAITemplate.contestGiveawayAnnouncements`}>Contest/Giveaway Announcements</Trans> },
    { value: "Industry Tips and Tricks", name: <Trans i18nKey={`askAITemplate.industryTipsandTricks`}>Industry Tips and Tricks</Trans> },
    { value: "Celebrating Milestones", name: <Trans i18nKey={`askAITemplate.celebratingMilestones`}>Celebrating Milestones</Trans> },
];

export const imageTypeOptions = [
    { 
        value: "ai", 
        name: "AI-image", 
        emoji: <BsStars className='h-6 text-2xl text-purple-500 !leading-[0px]' />, 
        configComponent: <AIImages isModal={true} />,
        ConfigIcon: TbSettings,
        tooltip: "Select AI image styles"
    },
    { 
        no_networks: ["medium"], 
        onlyDesktop: true,
        value: "creative", 
        name: "Creative image", 
        emoji: <LuImagePlus className='h-6 text-2xl text-primary-600 !leading-[0px]' />,
        configComponent: <CreativeTemplates isModal={true} />,
        ConfigIcon: TbSettings,
        tooltip: "Select creative templates"
    },
    { 
        value: "stock", 
        name: "Stock image", 
        emoji: <TbDatabaseStar className='h-6 text-2xl text-fuchsia-600 !leading-[0px]' /> 
    },
    { 
        no_networks: ["pinterest", "facebook", "instagram", "instagram_feed", "instagram_reel", "instagram_story", "tiktok", "linkedin"],
        value: "gif", 
        name: "GIF", 
        emoji: <TbGif className='h-6 text-2xl text-indigo-500 !leading-[0px]' /> 
    },
    { 
        templates: ["images", "news", "url"], 
        value: "source", 
        name: "Source image", 
        emoji: <LuFileImage className='h-6 text-2xl text-secondary-600 !leading-[0px]' />,
        default: ["images"] 
    },
    { 
        value: "none", 
        name: "No image", 
        emoji: <LuImageOff className='h-6 text-2xl !leading-[0px]' /> 
    },
]

export const postTypesOptions = [
    { 
        value: "twitter", 
        name: "Twitter post", 
        emoji: <img src="/img/socials/twitter.svg" className="h-6 w-6" />,
        allowMultiplePosts: true,
        makeMultiplePostsWhenTextLengthLongerThan: 400 
    },
    { 
        value: "instagram", 
        name: "Instagram post", 
        emoji: <img src="/img/socials/instagram.svg" className="h-6 w-6" /> 
    },
    { 
        value: "facebook", 
        name: "Facebook post", 
        emoji: <img src="/img/socials/facebook.svg" className="h-6 w-6" /> 
    },
    { 
        value: "pinterest", 
        name: "Pinterest post", 
        emoji: <img src="/img/socials/pinterest.svg" className="h-6 w-6" /> 
    },
    { 
        value: "linkedin", 
        name: "LinkedIn post", 
        emoji: <img src="/img/socials/linkedin.svg" className="h-6 w-6" /> 
    },
    { 
        value: "tiktok", 
        name: "Tiktok post", 
        emoji: <img src="/img/socials/tiktok.svg" className="h-6 w-6" /> 
    },
    { 
        value: "medium", 
        name: "Blog post", 
        emoji: <img src="/img/socials/medium.svg" className="h-6 w-6" />
    },
    ...(YOUTUBE_ENABLED ? [{
        value: "youtube",
        name: "Youtube video",
        emoji: <img src="/img/socials/youtube.svg" className="h-6 w-6" />
    }] : []),
]

const useConfigPanel = (template = undefined, network = undefined) => {

    const [ tones, setTones ] = useState(communicationStyleOptions);
    const [ formats, setFormats ] = useState(formatOptions);
    const [ ctas, setCtas ] = useState(ctaOptions);
    const [ imageTypes, setImageTypes ] = useState(imageTypeOptions.filter((e) => !e?.templates ? true : !template ? false : !!e?.templates?.includes(template)).filter((e) => !e?.no_networks ? true : !network ? true : !e?.no_networks?.includes(network)));
    const [ postTypes, setPostTypes ] = useState(postTypesOptions);

    const handleOnNewTone = (_new) => {
        setTones([{ value: _new, emoji: `👤`, name: _new }, ...tones]); 
        return true;
    }

    const handleOnNewFormat = (_new) => {
        setFormats([{ value: _new, emoji: `👤`, name: _new }, ...formats]); 
        return true;
    }

    const handleOnNewCta = (_new) => {
        setCtas([{ value: _new, emoji: `👤`, name: _new }, ...ctas]); 
        return true;
    }

    const getDefaultImageTypePerTemplate = (template) => {
        const defaultImageType = imageTypeOptions.find(ito => ito?.default?.includes(template));
        if (!!defaultImageType) {
            return defaultImageType.value;
        }
        return imageTypeOptions[0].value;
    }

    useEffect(() => {
        let _imageTypeOptions = imageTypeOptions;
        _imageTypeOptions = _imageTypeOptions.filter((e) => !e?.templates ? true : !template ? false : !!e?.templates?.includes(template)).filter((e) => !e?.no_networks ? true : !network ? true : !e?.no_networks?.includes(network));
        if (isMobile()) _imageTypeOptions = _imageTypeOptions.filter((e) => !e.onlyDesktop);
        setImageTypes(_imageTypeOptions)
    }, [template, network]);

    return { 
        tones, 
        formats, 
        ctas,
        imageTypes,
        postTypes,
        handleOnNewTone,
        handleOnNewFormat,
        handleOnNewCta,
        getDefaultImageTypePerTemplate
    }
}

export default useConfigPanel;