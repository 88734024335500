import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialCommentObj, formatSocialConversationObj, formatSocialMessageObj, formatSocialPostObj } from "../../formatSocialPostObj";
import { Notification, toast } from "components/ui";
import capitalize from "components/ui/utils/capitalize";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";

export const useTwitter = () => {

    const { company, companySocials } = useContext(CompanyContext);
    const _start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
    const _end_date = new Date();
    
    const getAvailablePlaces = async () => {
        return await Api.get(`/twitter/availableTrends`, {}).then(({ data: places }) => { return places });
    }

    const getTrendsByPlaceID = async (placeID) => {
        return await Api.get(`/twitter/placeTrends?id=${placeID}`).then(({ data: trends }) => { return trends[0].trends; })
    }

    const getPlaceIDByLatLong = async (lat, long, granularity = "country") => {
        const data = await Api.get(`/twitter/geocodes?lat=${lat}&long=${long}&granularity=${granularity}`).then((data) => { return data; });
        return data.data[0].id;
    }

    const searchTweets = async (
        q,
        place = "", // Miami, Florida
        result_type = "popular", // mixed, recent, popular
        count = 100,
        until = new Date(new Date().getTime() - 3600 * 24 * 1 * 1000), // max 7 days
        entities = true,
    ) => {
        until = new Date(until).toISOString().split('T')[0];
        q = encodeURIComponent(q);

        let placeId = undefined;
        if (place) {
            try {
                if (localStorage.getItem(`twitterPlace${place}`)) {
                    placeId = localStorage.getItem(`twitterPlace${place}`);
                } else {
                    let latLngObj = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${place}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`).then(response => response.json()).then(response => response?.results?.[0]?.geometry?.location);
                    placeId = await getPlaceIDByLatLong(latLngObj.lat, latLngObj.lng, "country");
                    if (placeId) localStorage.setItem(`twitterPlace${place}`, placeId);
                }
            } catch (err) {}
        }

        return await Api.get(`/twitter/searchTweets?${q ? `&q=${placeId == "TESTTESTTEST" ? `place:${placeId} ` : ``}${q}` : ``}${result_type ? `&result_type=${result_type}` : ``}${until ? `&until=${until}` : ``}${entities ? `&entities=true` : ``}${count ? `&count=${count}` : ``}`).then(({ data: tweets }) => { 
            tweets = tweets.map((tweet) => {
                const media = tweet?.entities?.media?.map((e) => { return { url: e?.media_url_https, type: e?.type == "photo" ? "image" : e?.type, width: e?.sizes?.large?.w, height: e?.sizes?.large?.h }})
                return formatSocialPostObj(
                    "twitter",
                    tweet?.id,
                    tweet?.user?.id,
                    tweet?.user?.name,
                    tweet?.user?.screen_name,
                    tweet?.user?.profile_image_url_https,
                    tweet?.created_at,
                    tweet?.full_text,
                    media,
                    tweet?.favorite_count,
                    0,
                    0,
                    tweet?.retweet_count
                )
            })
            return tweets; 
        });
    }

    // const getTwitterConversationMessages = async (
    //     conversation_id,
    //     per_page = 100, //100
    // ) => {

    //     const socialNetwork = getSocialNetworkIntegration("twitter", companySocials);
    //     if (!socialNetwork) return [];

    //     if (localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}`) && localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}_timestamp`)) {
    //         const timestamp = localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}_timestamp`);
    //         if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 30) {
    //             return JSON.parse(localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}`));
    //         }
    //     }

    //     let messages = [];
    //     let attachments = [];
    //     let users = [];
    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://api.twitter.com/2/dm_conversations/${conversation_id}/dm_events`,
    //             method: "get",
    //             params: {
    //                 max_results: per_page,
    //                 expansions: "attachments.media_keys",
    //                 "dm_event.fields": "dm_conversation_id,created_at,sender_id,attachments,participant_ids,referenced_tweets",
    //                 "user.fields": "name,profile_image_url,verified"
    //             }
    //         }).then((response) => response);
    //         messages = response?.data;
    //         attachments = response?.includes?.attachments;
    //         users = response?.includes?.users;
    //     } catch (err) {
    //         toast.push(<Notification type="danger" title={`An error ocurred while fetching ${capitalize(socialNetwork.type)} messages`} />)
    //     }

    //     if (messages) {
    //         messages = messages.map((item) => {
    //             const user = users?.filter(_user => _user.id == item?.sender_id)?.[0];
    //             return formatSocialMessageObj(
    //                 "twitter",
    //                 item?.id,
    //                 item?.created_at,
    //                 user?.id,
    //                 user?.name,
    //                 user?.username,
    //                 user?.profile_image_url,
    //                 user?.verified,
    //                 item?.text
    //             );
    //         });
    //         if (messages && messages?.length) {
    //             Object.entries(localStorage).map(x => x[0]).filter(x => x.includes(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}`)).map(x => localStorage.removeItem(x));
    //             localStorage.setItem(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}`, JSON.stringify(messages));
    //             localStorage.setItem(`${company?.id}_${socialNetwork.id}_conversation_messages_${conversation_id}_timestamp`, new Date().getTime());
    //         }
    //     }

    //     return messages && messages?.length ? messages : [];
    // }
    

    const sendTwitterMessage = async (conversation, message) => {

        const socialNetwork = getSocialNetworkIntegration("twitter", companySocials);
        if (!socialNetwork) return [];

        const conversation_id = conversation?.id;

        let _theMessage;
        try {
            const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
                url: `https://api.twitter.com/2/dm_conversations/${conversation_id}/messages`,
                method: "post",
                params: {
                    text: message,
                }
            }).then((response) => response);
            _theMessage = formatSocialConversationObj(
                "twitter",
                conversation_id,
                new Date().toISOString(),
                socialNetwork?.external_id,
                socialNetwork?.name,
                socialNetwork?.username,
                socialNetwork?.image?.url,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                message,
                true
            );
        } catch (err) {
            toast.push(<Notification type="danger" title={`An error ocurred while sending ${capitalize(socialNetwork.type)} message`} />);
        }

        if (_theMessage) {
            if (localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations`)) {
                let conversations = JSON.parse(localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations`));
                conversations[conversations?.findIndex(e => e.id == conversation_id)]?.messages?.unshift(_theMessage);
                conversations[conversations?.findIndex(e => e.id == conversation_id)].message = _theMessage?.message;
                localStorage.setItem(`${company?.id}_${socialNetwork.id}_conversations`, JSON.stringify(conversations));
            }
        }

        return _theMessage;
    }

    const getTwitterConversationMessages = async (
        conversation_id,
        per_page = 100, //100
    ) => {
        const conversations = await getTwitterUserConversations(100, false);
        return conversations?.filter(e => e.id == conversation_id)?.length ? conversations?.filter(e => e.id == conversation_id)?.[0]?.messages : [];
    }

    const getTwitterUserConversations = async (
        per_page = 100, // 100
        check_db = true
    ) => {
        const socialNetwork = getSocialNetworkIntegration("twitter", companySocials);
        if (!socialNetwork) return [];
        
        let conversations = [];
        
        if (localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations`) && localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations_timestamp`)) {
            const timestamp = localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations_timestamp`);
            if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 60 * 3.1) {
                try {
                    conversations = JSON.parse(localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations`));
                } catch (err) {} 
            }
        }

        if (!conversations?.length) {
            let users = [];
            try {
                const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
                    url: `https://api.twitter.com/2/dm_events`,
                    method: "get",
                    params: {
                        max_results: per_page,
                        expansions: "sender_id",
                        "dm_event.fields": "dm_conversation_id,created_at",
                        "user.fields": "name,profile_image_url,verified,created_at,description,public_metrics,url"
                    }
                }).then((response) => response);
                conversations = response?.data;
                users = response?.includes?.users;
                localStorage.setItem(`${company?.id}_${socialNetwork.id}_conversations_timestamp`, new Date().getTime());
            } catch (err) {
                // toast.push(<Notification type="danger" title={`An error ocurred while fetching ${capitalize(socialNetwork.type)} conversations`} />);
            }

            if (!conversations || conversations?.length == 0) {
                if (localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations`)) {
                    try {
                        conversations = JSON.parse(localStorage.getItem(`${company?.id}_${socialNetwork.id}_conversations`));
                    } catch (err) {}
                }
            }
    
            if (conversations && conversations?.length > 0) {
                conversations = conversations.map((item) => {
                    const user = users?.filter(_user => _user.id == item?.sender_id)?.[0];
                    return formatSocialConversationObj(
                        "twitter",
                        item?.dm_conversation_id,
                        item?.created_at,
                        user?.id,
                        user?.name,
                        user?.username,
                        user?.profile_image_url,
                        user?.verified,
                        user?.created_at,
                        user?.description,
                        user?.public_metrics?.followers_count,
                        user?.public_metrics?.following_count,
                        user?.public_metrics?.tweet_count,
                        user?.url,
                        item?.text,
                        user?.id == socialNetwork?.external_id
                    );
                });
    
                let _conversations = [];
                conversations.map((item) => {
                    if (_conversations?.filter(e => e.id == item?.id)?.length == 0) {
                        _conversations.push({...item, messages: []});
                    }
                    if (_conversations[_conversations?.findIndex(e => e.id == item.id)]?.user_id == socialNetwork?.external_id) {
                        _conversations[_conversations?.findIndex(e => e.id == item.id)] = {...item, messages: _conversations[_conversations?.findIndex(e => e.id == item.id)]?.messages}
                    }
                    _conversations[_conversations?.findIndex(e => e.id == item.id)]?.messages.push(item);
                });
                conversations = _conversations?.filter(e => e.user_id != socialNetwork?.external_id);
    
                conversations = conversations?.map((item) => {
                    return {
                        ...item, 
                        created_at: item?.messages?.[0]?.created_at,
                        message: item?.messages?.[0]?.message,
                        is_last_message_from_user: item?.messages?.[0]?.is_last_message_from_user
                    };
                });
            }
        }

        if (check_db) {
            try {
                const db_conversations = await Api.get(`/conversations?sort=-id&filter[social_network_id]=${socialNetwork?.id}&filter[type]=conversation&filter[externalUser.company_id]=${company.id}`).then(({ data: conversations }) => conversations);
                if (db_conversations && db_conversations?.length) {
                    conversations = conversations?.map((item) => {
                        const _db_conversation = db_conversations?.filter(e => e.external_id == item?.id)?.[0];
                        let _item = item;
                        if (_db_conversation) {
                            _item = {
                                ...item,
                                db_id: _db_conversation?.id,
                                assigned_user_id: _db_conversation?.user_id,
                                last_read_at: _db_conversation?.last_read_at
                            }
                        }
                        return _item;
                    });
                }
            } catch (err) {}

            conversations = conversations?.map((item) => {
                return {
                    ...item,
                    unreadMessages_count: (!item?.last_read_at || (new Date(item.last_read_at).getTime() < new Date(item.created_at))) && !item.is_last_message_from_user ? 1 : 0
                }
            });
    
            if (conversations && conversations?.length) {
                localStorage.setItem(`${company?.id}_${socialNetwork.id}_conversations`, JSON.stringify(conversations));
            }
        }

        return conversations && conversations?.length ? conversations : [];
    }

    const getTwitterUserPosts = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("twitter", companySocials);
        if (!socialNetwork) return [];

        start_date = start_date.toISOString();
        end_date = end_date.toISOString();

        let posts = [];

        if (localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`)) {
            const timestamp = localStorage.getItem(`${socialNetwork.id}_posts_timestamp_${start_date.split("T")[0]}_${end_date.split("T")[0]}`);
            if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 3600 * 2) { // ONCE EVERY 2 HOURS 
                posts = JSON.parse(localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`));
            }
        }

        if (!posts?.length) {
            try {
                posts = await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${per_page ? `&max_results=${per_page}` : ``}${start_date ? `&start_time=${start_date}` : ``}${end_date ? `&end_time=${end_date}` : ``}&tweet.fields=referenced_tweets,entities,created_at,non_public_metrics,public_metrics&expansions=in_reply_to_user_id,entities.mentions.username,referenced_tweets.id,attachments.media_keys,author_id&media.fields=type,url,preview_image_url,width,height&user.fields=profile_image_url,verified`).then(({ data: posts, includes: includes }) => { 
                    posts = posts?.filter(tweet => !tweet?.referenced_tweets?.filter(e => e?.type == "replied_to")?.length)
                    posts = posts?.map((tweet) => {
                        const user = includes?.users?.filter(_user => _user.id == tweet.author_id)?.[0];
                        const media = includes?.media?.filter(_media => tweet?.attachments?.media_keys?.includes(_media?.media_key))?.map((e) => { return {...e, type: e.type == "photo" || e.type == "animated_gif" ? "image" : e.type} });
                        let text = tweet?.text;
                        tweet?.entities?.urls?.map((urlObj) => {
                            text = replaceAll(text, urlObj?.url, `<a href="${urlObj?.unwound_url}" target="_blank">${urlObj?.display_url}</a>`);
                        });
                        return formatSocialPostObj(
                            "twitter",
                            tweet?.id,
                            user?.id,
                            user?.name,
                            user?.username,
                            user?.profile_image_url,
                            tweet?.created_at,
                            text,
                            media,
                            tweet?.public_metrics?.like_count,
                            tweet?.public_metrics?.reply_count,
                            tweet?.public_metrics?.impression_count,
                            tweet?.public_metrics?.quote_count + tweet?.public_metrics?.retweet_count,
                            "",
                            "",
                            "",
                            user?.verified
                        )
                    });
                    localStorage.setItem(`${socialNetwork.id}_posts_timestamp_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, new Date().getTime());
                    return posts; 
                });
    
                // TWITTER COMMENTS (QUOTA LIMIT TOO LOW)
                // let comments = [];
                // if (posts?.length) {
                //     try {
                //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
                //             url: `https://api.twitter.com/2/tweets/search/recent`,
                //             method: "get",
                //             params: {
                //                 query: `(conversation_id:${posts?.map(e => e.id)?.split(" OR conversation_id:")})`,
                //                 "tweet.fields": `referenced_tweets,entities,created_at,non_public_metrics,public_metrics`,
                //                 "media.fields": `type,url,preview_image_url,width,height`,
                //                 "user.fields": `name,profile_image_url,verified,created_at,description,public_metrics,url`,
                //                 expansions: `in_reply_to_user_id,entities.mentions.username,referenced_tweets.id,attachments.media_keys,author_id`,
                //             }
                //         });
                //         comments = response?.data;
                //         const includes = response?.includes;
                //         comments = comments?.map((tweet) => {
                //             const user = includes?.users?.filter(_user => _user.id == tweet.author_id)?.[0];
                //             const media = includes?.media?.filter(_media => tweet?.attachments?.media_keys?.includes(_media?.media_key))?.map((e) => { return {...e, type: e.type == "photo" || e.type == "animated_gif" ? "image" : e.type} });
                //             let text = tweet?.text;
                //             tweet?.entities?.urls?.map((urlObj) => {
                //                 text = replaceAll(text, urlObj?.url, `<a href="${urlObj?.unwound_url}" target="_blank">${urlObj?.display_url}</a>`);
                //             });
                //             return formatSocialCommentObj(
                //                 "twitter",
                //                 tweet?.id,
                //                 tweet?.created_at,
                //                 user?.id,
                //                 user?.name,
                //                 user?.username,
                //                 user?.profile_image_url,
                //                 text,
                //                 media,
                //                 [],
                //             );
                //         });
                //     } catch (err) {}
                // }
    
            } catch (err) {}
        }

        // TWITTER COMMENTS (QUOTA LIMIT TOO LOW)
        // let db_comments = [];
        // if (posts?.length) {
        //     try {
        //         db_comments = await Api.get(`/conversations?sort=-id&filter[social_network_id]=${socialNetwork?.id}&filter[type]=comment`).then(({ data: _db_comments }) => _db_comments);
        //     } catch (err) {}
        //     posts = posts?.map((post) => {
        //         post.comments = post?.comments?.map((comment) => {
        //             return {
        //                 ...comment,
        //                 last_read_at: db_comments?.filter(e => e?.external_id == comment?.id)?.[0]?.last_read_at,
        //                 assigned_user_id: db_comments?.filter(e => e?.external_id == comment?.id)?.[0]?.user_id,
        //             }
        //         });
        //         return post;
        //     });
        // }

        if (posts) {
            localStorage.setItem(`${company?.id}_${socialNetwork.id}_posts_${start_date.split("T")[0]}${end_date.split("T")[0]}`, JSON.stringify(posts));
        }

        return posts;
    }

    return { 
        getAvailablePlaces,
        getTrendsByPlaceID,
        searchTweets,
        getTwitterUserPosts,
        getTwitterUserConversations,
        getTwitterConversationMessages,
        sendTwitterMessage 
    }
}

