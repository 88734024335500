import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'
import { isEmpty } from 'lodash'

const AuthorityCheck = (props) => {
    const { userAuthority = [], authority = [], children } = props

    const roleMatched = useAuthority(userAuthority, authority)
    
    if (!!authority.find(e => e === 'sysadmin') && !userAuthority.find(e => e === 'sysadmin')) {
        return <></>
    }

    return roleMatched ? children : <></>
}

AuthorityCheck.propTypes = {
    userAuthority: PropTypes.array,
    authority: PropTypes.array,
}

export default AuthorityCheck
