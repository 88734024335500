import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout' 
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { CompanyContext, CompanyProvider } from 'contexts/CompanyContext'
import { CalendarActiveSocialNetworkProvider } from 'contexts/CalendarActiveSocialNetworkContext'
import { ModalProvider } from 'contexts/ModalContext'
import { SessionProvider } from 'contexts/SessionContext'
import { TrendingNewsProvider } from 'contexts/TrendingNewsContext'
import { UploadProvider } from 'contexts/UploadContext'
import { MediaProvider } from 'contexts/MediaContext'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PriorityModalProvider } from 'contexts/PriorityModalContext'
import { CopyProvider } from 'contexts/CopyContext'
import { ConversationProvider } from 'contexts/ConversationContext'
import { PostProvider } from 'contexts/PostContext'
import { AIProvider } from 'contexts/AIContext'
import { ScheduledPostProvider } from 'contexts/ScheduledPostContext'
import { AppSumoProvider } from 'contexts/AppSumoContext'
import { TagProvider } from 'contexts/TagContext'
import { ContextMenuProvider } from 'contexts/ContextMenuContext'
import { CompanyPromptsProvider } from 'contexts/CompanyPromptsContext'
import Cookies from 'universal-cookie'
import { MaintenanceProvider } from 'contexts/MaintenanceContext'
import { RuleGroupsProvider } from 'contexts/RuleGroupsContext'
import { BannedProvider } from 'contexts/BannedContext'
import CookieConsent from 'components/shared/CookieConsent'

const environment = process.env.NODE_ENV

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
/**
* Set enableMock(Default false) to true at configs/app.config.js
* If you wish to enable mock api
*/

function App() {

  useEffect(() => {
    const cookies = new Cookies();
    if (!cookies.get("referrer_url")) {
      cookies.set('referrer_url', document.referrer || `null`, { 
        domain: "followr.ai", 
        expires: new Date(new Date().getTime() + 3600 * 24 * 365 * 1000),
        secure: false,
        path: "/" 
      });
    }
    if (!cookies.get("land_url")) {
      cookies.set('land_url', window.location.href || `null`, { 
        domain: "followr.ai", 
        expires: new Date(new Date().getTime() + 3600 * 24 * 365 * 1000),
        secure: false,
        path: "/" 
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MaintenanceProvider>
          <SessionProvider>
            <BannedProvider>
              <CompanyProvider>
                <ModalProvider>
                  <PriorityModalProvider>
                    <AppSumoProvider>
                      <Elements stripe={stripePromise}>
                        <AIProvider>
                          <TagProvider>
                            <ScheduledPostProvider>
                              <RuleGroupsProvider>
                                <MediaProvider>
                                  <CopyProvider>
                                    <CalendarActiveSocialNetworkProvider>
                                      <PostProvider>
                                        <ConversationProvider>
                                          <TrendingNewsProvider>
                                            <UploadProvider>
                                              <ContextMenuProvider>
                                                <BrowserRouter history={history}>
                                                  <CompanyPromptsProvider>
                                                    <Theme>
                                                      <Layout />
                                                    </Theme>
                                                  </CompanyPromptsProvider>
                                                </BrowserRouter>
                                              </ContextMenuProvider>
                                            </UploadProvider>
                                          </TrendingNewsProvider>
                                        </ConversationProvider>
                                      </PostProvider>
                                    </CalendarActiveSocialNetworkProvider>
                                  </CopyProvider>
                                </MediaProvider>
                              </RuleGroupsProvider>
                            </ScheduledPostProvider>
                          </TagProvider>
                        </AIProvider>
                      </Elements>
                    </AppSumoProvider>
                  </PriorityModalProvider>
                </ModalProvider>
              </CompanyProvider>
            </BannedProvider>
          </SessionProvider>
        </MaintenanceProvider>
        <CookieConsent />
      </PersistGate>
    </Provider>
  )
}

export default App
