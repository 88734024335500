import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import cookieImg from 'assets/images/other/cookie.png';
import { loadScript, loadInlineScript, removeTrackingScripts } from 'utils/loadScript';
import { COOKIE_DOMAIN } from 'components/ui/utils/constant';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const cookies = new Cookies();

  const loadTrackingScripts = () => {
    // Google Tag Manager
    loadInlineScript(`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N585ZQ8');
    `, 'gtm-script');

    // Google Analytics
    loadScript('https://www.googletagmanager.com/gtag/js?id=AW-11483401020', 'gtag-script')
      .then(() => {
        loadInlineScript(`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11483401020');
        `, 'gtag-config');
      });

    // Meta Pixel
    loadInlineScript(`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '631697692455753');
      fbq('track', 'PageView');
    `, 'meta-pixel-script');

    // Add Meta Pixel noscript fallback
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.style.display = 'none';
    img.src = 'https://www.facebook.com/tr?id=631697692455753&ev=PageView&noscript=1';
    noscript.appendChild(img);
    document.body.appendChild(noscript);
  };

  useEffect(() => {
    // Check if user has already consented
    const hasConsented = cookies.get('cookie_consent');
    if (hasConsented === undefined) {
      setTimeout(() => {
        setIsVisible(true);
      }, 4000);
    } else if (hasConsented === true) {
      // Load tracking scripts if consent was previously given
      loadTrackingScripts();
    }
  }, []);

  const handleAccept = () => {
    // Set cookie consent for 1 year
    cookies.set('cookie_consent', true, {
      path: '/',
      domain: COOKIE_DOMAIN.includes("localhost") ? undefined : COOKIE_DOMAIN || "followr.ai",
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
      secure: true,
      sameSite: 'strict'
    });
    loadTrackingScripts();
    setIsVisible(false);
  };

  const handleDecline = () => {
    // Set cookie preference to declined
    cookies.set('cookie_consent', false, {
      path: '/',
      domain: COOKIE_DOMAIN.includes("localhost") ? undefined : COOKIE_DOMAIN || "followr.ai",
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
      secure: true,
      sameSite: 'strict'
    });

    // Remove tracking scripts
    removeTrackingScripts();

    // Strings to match in cookie names
    const cookiePatterns = [
      '_ga',
      '_gid',
      '_gat',
      '_gcl',
      '_fbp',
      'fr',
      '_gtm',
      'datr',
      'sb',
      'wd',
      'dpr'
    ];

    // Get all cookies
    const allCookies = cookies.getAll();

    // Remove matching cookies
    Object.keys(allCookies).forEach(cookieName => {
      const matchesPattern = cookiePatterns.some(pattern => 
        cookieName.toLowerCase().includes(pattern.toLowerCase())
      );

      if (matchesPattern) {
        // Try removing from all possible domain levels
        [
          window.location.hostname,
          `.${window.location.hostname}`,
          COOKIE_DOMAIN.includes("localhost") ? undefined : COOKIE_DOMAIN || "followr.ai"
        ].forEach(domain => {
          if (domain) {
            cookies.remove(cookieName, {
              path: '/',
              domain: domain
            });
          }
        });
      }
    });

    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 md:bottom-4 border border-gray-50 dark:border-transparent right-0 md:right-4 md:right-4 md:max-w-md bg-white dark:bg-gray-900 rounded-t-xl md:rounded-xl shadow-xl p-6 z-50">
      <div className="flex flex-col items-center text-center gap-4">
        <img src={cookieImg} alt="Cookie" className="w-16 h-16" />
        
        <h3 className="text-xl font-bold">
          Have a cookie :{`)`}
        </h3>
        
        <p className="text-sm text-gray-600 dark:text-gray-300">
          We use cookies to enhance your experience and make our AI smarter (and maybe a bit chubbier). By continuing, you agree to our <a href="https://followr.ai/cookie-policy" target="_blank" className="text-primary-600 hover:text-primary-700">cookie policy</a>.
        </p>

        <div className="flex flex-col sm:flex-row gap-2 w-full mt-2">
          <button
            onClick={handleAccept}
            className="w-full px-4 font-semibold py-2 text-white bg-primary-600 hover:bg-primary-700 rounded-lg transition-colors"
          >
            Accept all cookies
          </button>
          <button
            onClick={handleDecline}
            className="w-full px-4 py-2 text-gray-600 dark:text-gray-300 !bg-gray-600/10 hover:!bg-gray-100 dark:hover:!bg-gray-700 rounded-lg transition-colors"
          >
            Decline optional cookies
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
