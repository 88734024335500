import { Button, Card, Dialog, Notification, Progress, Radio, Select, toast } from 'components/ui';
import InputModern from 'components/ui/Input/InputModern';
import { CompanyContext } from 'contexts/CompanyContext';
import React, { useContext, useEffect, useState } from 'react'
import { HiCheckCircle, HiMinus, HiOutlineCreditCard, HiOutlineMinus, HiPlus } from 'react-icons/hi';
import { HiChevronRight, HiOutlineCheck, HiOutlineCheckCircle, HiOutlinePlus, HiRocketLaunch } from 'react-icons/hi2';
import Api, { API_HOST } from 'services/Api';
import { formatNumber } from 'utils/formatNumber';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { SessionContext } from 'contexts/SessionContext';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import ProductPlan from './ProductPlan';
import MySubscription from './MySubscription';
import CardDialog from './CardDialog';
import { Loading } from 'components/shared';
import { Trans } from 'react-i18next';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import { PUBLIC_URL } from 'components/ui/utils/constant';
import { objectToQueryParams } from 'utils/objectToQueryParams';

const Subscription = ({ isModal = false }) => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { user, updateUserData } = useContext(SessionContext);
    const [ searchParams ] = useSearchParams();
    const [ products, setProducts ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ billingFrequency, setBillingFrequency ] = useState("year");
    const [ additionalUsers, setAdditionalUsers ] = useState(0);
    const [ additionalCompanies, setAdditionalCompanies ] = useState(0);
    const [ selectedPlan, setSelectedPlan ] = useState(2);
    const [ selectedAdditionalWords, setSelectedAdditionalWords ] = useState(0);
    const [ selectedAdditionalImages, setSelectedAdditionalImages ] = useState(0);
    const [ subscriptionCreated, setSubscriptionCreated] = useState(false);
    const [ total, setTotal ] = useState(0);
    const [ cardDialogOpened, setCardDialogOpened ] = useState(false);
    const [ isSubmittingCard, setIsSubmittingCard ] = useState(false);
    const [ isSubmittingChangePlan, setIsSubmittingChangePlan ] = useState(false);
    
    const stripe = useStripe();
    const elements = useElements();

    const freeTrialdEndsAt = new Date().getTime() + 1000 * 3600 * 24 * process.env.REACT_APP_FREE_TRIAL_DAYS;

    const discountText = process.env.REACT_APP_DEFAULT_DISCOUNT;
    const discountFactor = parseFloat(discountText) ? 1 - parseFloat(discountText) / 100 : 1;

    const handleSubmitStripe = async (event, stripeCheckout = false) => {

        event && event?.preventDefault();
        // if (elements == null) {
        //     return;
        // }
        
        setIsSubmittingCard(true);
        setIsSubmittingChangePlan(true);

        const planObj = (products.filter(item => item.id == selectedPlan))[0];
        const planPriceObj = (planObj.prices.filter(price => price.recurring == billingFrequency))[0];

        const addUserObj = (products.filter(item => item.name == 'add-user'))[0];
        const userPriceObj = (addUserObj.prices.filter(price => price.recurring == billingFrequency))[0];

        const addCompanyObj = (products.filter(item => item.name == 'add-company'))[0];
        const companyPriceObj = (addCompanyObj.prices.filter(price => price.recurring == billingFrequency))[0];
        
        let wordsPriceObj;
        if (selectedAdditionalWords > 0) {
            const wordsObj = (products.filter(item => item.id == selectedAdditionalWords))[0];
            wordsPriceObj = (wordsObj.prices.filter(price => price.recurring == billingFrequency))[0];
        }
        
        let imagesPriceObj;
        if (selectedAdditionalImages > 0) {
            const imagesObj = (products.filter(item => item.id == selectedAdditionalImages))[0];
            imagesPriceObj = (imagesObj.prices.filter(price => price.recurring == billingFrequency))[0];
        }
        
        let _paymentMethod;
        if (!stripeCheckout) {
            if (!user?.pm_last_four || !user?.subscription?.id) {
                const {error, paymentMethod} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                });
                if (error) {
                    setIsSubmittingCard(false);
                    setIsSubmittingChangePlan(false);
                    toast.push(<Notification title={error.message} type="warning" />, { placement: 'top-center' });
                    return;
                }
                _paymentMethod = paymentMethod;
            }
        }

        let data = {
            resume: 1,
            payment_method_id: _paymentMethod?.id || undefined,
            prices: [{ "id": planPriceObj.id }],
            referral: window?.Rewardful?.referral ? window?.Rewardful?.referral : undefined,
            promotional_code: process.env?.REACT_APP_DEFAULT_COUPON_CODE ? process.env?.REACT_APP_DEFAULT_COUPON_CODE : undefined,
            checkout: stripeCheckout ? 1 : 0,
            success_url: `${PUBLIC_URL}/subscription?success=1`,
            cancel_url: `${PUBLIC_URL}/subscription?success=0`,
        };
        
        additionalUsers ? data.prices.push({ "id": userPriceObj.id, "quantity": additionalUsers }) : void 0;
        additionalCompanies ? data.prices.push({ "id": companyPriceObj.id, "quantity": additionalCompanies }) : void 0;
        selectedAdditionalWords && wordsPriceObj ? data.prices.push({ "id": wordsPriceObj.id }) : void 0;
        selectedAdditionalImages && imagesPriceObj ? data.prices.push({ "id": imagesPriceObj.id }) : void 0;

        if (stripeCheckout) {
            window.location.href = `${API_HOST}/api/subscriptions/checkout?token=${localStorage.getItem(`token`)}&${objectToQueryParams(data)}`;
            return;
        }
        
        if (!user?.subscription?.id) {
            Api.post(`/subscriptions`, {}, data).then((response) => {
                setSubscriptionCreated(true);
                try {
                    if (window?.fbq) {
                        window?.fbq('track', 'StartTrial', {
                            'value': user?.id,
                            'curreny': 'USD',
                            'subscription_id': user?.id 
                        });
                        window?.fbq('track', 'Purchase', {
                            'value': user?.id,
                            'curreny': 'USD',
                            'content_ids': [`${user?.id}`],
                            'content_type': 'product'
                        });
                    }
                    if (window?.gtag) {
                        window?.gtag('event', 'conversion', {
                            'send_to': 'AW-11483401020/KReUCK_n-YwZELyW2-Mq',
                            'value': total,
                            'currency': 'USD',
                            'id': user?.id
                        });
                        window.gtag('event', 'purchase', {
                            'transaction_id': user?.id,
                            'value': total,
                            'currency': 'USD',
                            'items': [{
                              'id': selectedPlan,
                              'name': selectedPlan,
                              'quantity': 1,
                              'price': total
                            }]
                        });
                    }
                } catch (err) {}
                setTimeout(() => {
                    updateUserData();
                }, 8000);
            }).catch((err) => {
                toast.push(<Notification title={err?.message || 'An error ocurred. Please, try again later'} type="danger" />, { placement: 'top-center' });
            }).finally(() => {
                setIsSubmittingCard(false);
                setIsSubmittingChangePlan(false);
            });        
        } else {
            Api.put(`/subscriptions`, {}, data).then((response) => {
                updateUserData();
                toast.push(<Notification title="Your subscription has been successfully modified." type="success" />, { placement: 'top-center' });
                closeModalPriority();
            }).catch((err) => {
                toast.push(<Notification title={err.message} type="danger" />, { placement: 'top-center' });
            }).finally(() => {
                setIsSubmittingChangePlan(false);
                setIsSubmittingCard(false);
            });
        }
    }


    const addCompanies = () => {
        setAdditionalCompanies(additionalCompanies + 1);
    }
    
    const addUsers = () => {
        setAdditionalUsers(additionalUsers + 1);
    }

    const removeCompanies = () => {
        if (additionalCompanies == 0) return;
        setAdditionalCompanies(additionalCompanies - 1);
    }
    
    const removeUsers = () => {
        if (additionalUsers == 0) return;
        setAdditionalUsers(additionalUsers - 1);
    }

    const handleOnNext = () => {
        if (!user?.pm_last_four || !user?.subscription?.id) {
            setCardDialogOpened(true)
        } else {
            handleSubmitStripe();
        }
    }

    useEffect(() => {
        setIsLoading(true);
        Api.get(`/products?include=prices`).then(({ data: products }) => {
            if (!user?.subscription && process.env.REACT_APP_HIDDEN_PLANS_WHEN_NO_SUBSCRIPTION) {
                const hiddenPlanNames = process.env.REACT_APP_HIDDEN_PLANS_WHEN_NO_SUBSCRIPTION.split(" ");
                products = products?.filter(e => !hiddenPlanNames?.includes(e?.name));
            }
            setProducts(products);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!selectedPlan || products?.length == 0) {
            setTotal(0);
            return;
        }
        const planObj = (products.filter(item => item.id == selectedPlan))[0];
        const planPriceObj = (planObj.prices.filter(price => price.recurring == billingFrequency))[0];
        const planPrice = Math.floor(planPriceObj?.amount / 100);

        const addUserObj = (products.filter(item => item.name == 'add-user'))[0];
        const userPriceObj = (addUserObj.prices.filter(price => price.recurring == billingFrequency))[0];
        const userPrice = Math.floor(userPriceObj?.amount * additionalUsers / 100);

        const addCompanyObj = (products.filter(item => item.name == 'add-company'))[0];
        const companyPriceObj = (addCompanyObj.prices.filter(price => price.recurring == billingFrequency))[0];
        const companyPrice = Math.floor(companyPriceObj?.amount * additionalCompanies / 100);
        
        let wordsPrice = 0;
        if (selectedAdditionalWords > 0) {
            const wordsObj = (products.filter(item => item.id == selectedAdditionalWords))[0];
            const wordsPriceObj = (wordsObj.prices.filter(price => price.recurring == billingFrequency))[0];
            wordsPrice = Math.floor(wordsPriceObj?.amount / 100);
        }
        
        let imagesPrice = 0;
        if (selectedAdditionalImages > 0) {
            const imagesObj = (products.filter(item => item.id == selectedAdditionalImages))[0];
            const imagesPriceObj = (imagesObj.prices.filter(price => price.recurring == billingFrequency))[0];
            imagesPrice = Math.floor(imagesPriceObj?.amount / 100);
        }

        setTotal(planPrice * discountFactor + userPrice + companyPrice + wordsPrice + imagesPrice);

    }, [products, selectedPlan, billingFrequency, additionalUsers, additionalCompanies, selectedAdditionalWords, selectedAdditionalImages])

    useEffect(() => {
        if (!!user?.subscription) {
            const actualAddOns = user?.subscription?.items?.filter(item => item.product.type == "add-on");
            const companies = actualAddOns?.filter(item => item.product.name == "add-company")?.[0]?.quantity;
            const users = actualAddOns?.filter(item => item.product.name == "add-user")?.[0]?.quantity;
            setBillingFrequency(user?.subscription?.items?.[0]?.price?.recurring);
            setAdditionalUsers(users || 0);
            setAdditionalCompanies(companies || 0);
            setSelectedPlan(user?.subscription?.items?.filter(item => item.product.type == "plan")?.[0]?.product?.id || 1)
        }
    }, []);
    
    if (isLoading) {
        return (
            <div className='w-full h-full flex items-center justify-center'>
                <Loading type={`element`} />
            </div>
        )
    }

    if (!!user?.subscription?.id && !isModal) {
        return <MySubscription />
    }

    return (
        <>
            <div className='flex flex-col lg:flex-row w-full gap-4 p-4 !pl-0 !pr-0 lg:p-6 lg:!pl-6 lg:!pr-6'>
                <div className='w-full flex flex-row lg:flex-col whitespace-nowrap lg:whitespace-normal overflow-x-scroll lg:overflow-auto gap-4 pl-4 pr-4 lg:pl-0 lg:pr-0'>
                    {products && products.filter(item => item.type == "plan").sort((a, b) => a?.prices?.[0].amount - b?.prices?.[0].amount).map((item, key) => (
                        <ProductPlan 
                            key={key} 
                            index={key}
                            setSelectedPlan={setSelectedPlan}
                            item={item} 
                            selectedPlan={selectedPlan}
                            billingFrequency={billingFrequency} 
                        />
                    ))}
                </div>
                <div className='shrink-0 px-4 lg:px-0'>
                    <Card bodyClass="flex flex-col !px-0 gap-6 py-8 pb-6 h-auto">
                        <div className='flex flex-col gap-4 px-6'>
                            <h6 className='font-bold text-gray-900'><Trans i18nKey={`subscription.billingFrequency`}>Billing Frequency</Trans></h6>
                            <Radio.Group vertical value={billingFrequency} onChange={(val) => setBillingFrequency(val)}>
                                <Radio className="flex items-center justify-between gap-20" value={'month'}><Trans i18nKey={`subscription.monthly`}>Monthly</Trans></Radio>
                                <Radio className="flex items-center justify-between gap-20" value={'year'}>
                                    <div className='flex items-center gap-2 w-full'>
                                        <span><Trans i18nKey={`subscription.annualy`}>Annualy</Trans></span> 
                                        <Button variant="solid" className='font-bold rounded-lg !p-1 !px-2.5 inline-block !h-auto !text-xs flex gap-1.5 items-center bg-primary w-auto'>
                                            <HiRocketLaunch />
                                            <span><Trans i18nKey={`subscription.save`}>Save</Trans> 35%</span>
                                        </Button>
                                    </div>
                                </Radio>
                            </Radio.Group>
                        </div>
                        <hr />
                        <div className='flex flex-col gap-4 px-6'>
                            <h6 className='font-bold text-gray-900'><Trans i18nKey={`subscription.customizeYourPlan`}>Customize Your Plan</Trans></h6>
                            <div>
                                <label className='px-2 mb-1 !text-xs flex w-full items-center justify-between'>
                                    <span><Trans i18nKey={`subscription.additionalCompanies`}>Additional Companies</Trans></span>
                                    <span className='text-primary-600'>+${formatNumber(parseInt(products?.filter(e => e.name.includes('add-company'))[0]?.prices?.filter(e => e.recurring == billingFrequency)[0]?.amount) / (billingFrequency == 'year' ? 12 : 1) / 100)} / month</span>
                                </label>
                                <div className='w-full relative select-none'>
                                    <InputModern value={additionalCompanies} className="text-center pointer-events-none select-none" />
                                    <HiMinus onClick={removeCompanies} className='text-lg absolute text-primary-600 left-3 top-1/2 -translate-y-1/2 cursor-pointer' />
                                    <HiPlus onClick={addCompanies} className='text-lg absolute text-primary-600 right-3 top-1/2 -translate-y-1/2 cursor-pointer' />
                                </div>
                            </div>
                            <div>
                                <label className='px-2 mb-1 !text-xs flex w-full items-center justify-between'>
                                    <span><Trans i18nKey={`subscription.additionalUsers`}>Additional Users</Trans></span>
                                    <span className='text-primary-600'>+${formatNumber(parseInt(products?.filter(e => e.name.includes('add-user'))[0]?.prices?.filter(e => e.recurring == billingFrequency)[0]?.amount) / (billingFrequency == 'year' ? 12 : 1) / 100)} / month</span>
                                </label>
                                <div className='w-full relative select-none'>
                                    <InputModern value={additionalUsers} className="text-center pointer-events-none select-none" />
                                    <HiMinus onClick={removeUsers} className='text-lg absolute text-primary-600 left-3 top-1/2 -translate-y-1/2 cursor-pointer' />
                                    <HiPlus onClick={addUsers} className='text-lg absolute text-primary-600 right-3 top-1/2 -translate-y-1/2 cursor-pointer' />
                                </div>
                            </div>
                            <div>
                                <label className='px-2 mb-1 !text-xs flex w-full items-center justify-between'>
                                    <span><Trans i18nKey={`subscription.additionalWords`}>Additional AI-Words</Trans></span>
                                </label>
                                <Select 
                                    value={selectedAdditionalWords > 0 
                                        ? { value: selectedAdditionalWords, label: `${formatNumber(products.filter(e => e.id == selectedAdditionalWords)[0]?.words_amount)} ($${formatNumber(products.filter(e => e.id == selectedAdditionalWords)[0]?.prices?.filter(e => e.recurring == billingFrequency)[0].amount / (billingFrequency == 'year' ? 12 : 1) / 100)} / month)` }
                                        : { value: "0", label: "No additional words" }
                                    } 
                                    placeholder=""
                                    onChange={(option) => setSelectedAdditionalWords(option.value)}
                                    options={[
                                        { value: "0", label: "No additional words" },
                                        ...products.filter(e => e.name.includes("words")).map((item) => {
                                            return {
                                                value: item?.id,
                                                label: `${formatNumber(item?.words_amount)} ($${formatNumber(item?.prices?.filter(e => e.recurring == billingFrequency)[0].amount / (billingFrequency == 'year' ? 12 : 1) / 100)} / month)`
                                            }
                                        })
                                    ]}
                                />
                            </div>
                            <div>
                                <label className='px-2 mb-1 !text-xs flex w-full items-center justify-between'>
                                    <span><Trans i18nKey={`subscription.additionalImages`}>Additional AI-Images</Trans></span>
                                </label>
                                <Select 
                                    value={selectedAdditionalImages > 0 
                                        ? { value: selectedAdditionalImages, label: `${formatNumber(products.filter(e => e.id == selectedAdditionalImages)[0]?.images_amount)} ($${formatNumber(products.filter(e => e.id == selectedAdditionalImages)[0]?.prices?.filter(e => e.recurring == billingFrequency)[0].amount / (billingFrequency == 'year' ? 12 : 1) / 100)} / month)` }
                                        : { value: "0", label: "No additional images" }
                                    } 
                                    placeholder=""
                                    onChange={(option) => setSelectedAdditionalImages(option.value)}
                                    options={[
                                        { value: "0", label: "No additional images" },
                                        ...products.filter(e => e.name.includes("images")).map((item) => {
                                            return {
                                                value: item?.id,
                                                label: `${formatNumber(item?.images_amount)} ($${formatNumber(item?.prices?.filter(e => e.recurring == billingFrequency)[0].amount / (billingFrequency == 'year' ? 12 : 1) / 100)} / month)`
                                            }
                                        })
                                    ]}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className='px-6 flex flex-col gap-2'>
                            <div className='flex items-center justify-between mega-title'>
                                <span className='text-gray-800 dark:text-gray-200 text-base'><Trans i18nKey={`subscription.monthlyTotal`}>Monthly total</Trans></span>
                                <h3>${formatNumber(total / (billingFrequency == "year" ? 12 : 1), 2)}</h3>
                            </div>
                            {billingFrequency == "year" && 
                                <div className='flex items-center justify-between'>
                                    <small><Trans i18nKey={`subscription.billedYearly`}>Billed yearly</Trans></small>
                                    <small>${formatNumber(total, 2)}</small>
                                </div>
                            }
                        </div>
                        <hr />
                        <div className='px-6'>
                            <Button onClick={() => handleOnNext()} disabled={!selectedPlan || !products || total == 0 || isSubmittingChangePlan} loading={isSubmittingChangePlan} variant="solid" size="lg" className="flex justify-center gap-4 items-center !px-4 w-full !text-sm">
                                {!!user?.subscription 
                                    ? <span><Trans i18nKey={`subscription.changePlan`}>Change Plan</Trans></span>
                                    : !!parseInt(process.env.REACT_APP_FREE_TRIAL_DAYS)
                                        ? <span><Trans i18nKey={`subscription.startFreeTrial`}>Start {{ days: process.env.REACT_APP_FREE_TRIAL_DAYS }}-Day Free Trial</Trans></span>
                                        : <span><Trans i18nKey={`subscription.subscribe`}>Subscribe</Trans></span>
                                }
                                <HiChevronRight />
                            </Button>
                            {!user?.subscription && <>
                                {!!parseInt(process.env.REACT_APP_FREE_TRIAL_DAYS) ? <>
                                    <small className='text-center mt-2 w-full block !text-xs'>
                                        <Trans i18nKey={`subscription.youWontBeChargedUntil`}>You won't be charged until</Trans> &nbsp;
                                        {new Date(freeTrialdEndsAt).toLocaleDateString()}.
                                    </small>
                                </> : <>
                                    <small className='w-full block text-center !text-xs mt-2'><Trans i18nKey={`subscription.refundPolicy`}>15-day money back guarantee.</Trans></small>
                                </>}
                                <small className='w-full block text-center !text-xs mt-2'><Trans i18nKey={`subscription.youCanCancel`}>You can cancel at any time.</Trans></small>
                            </>}
                        </div>
                    </Card>
                </div>
            </div>
            
            <CardDialog 
                cardDialogOpened={cardDialogOpened} 
                setCardDialogOpened={setCardDialogOpened} 
                handleSubmitStripe={handleSubmitStripe} 
                isSubmittingCard={isSubmittingCard}
                subscriptionCreated={subscriptionCreated}
                handleStripeCheckout={() => handleSubmitStripe(undefined, true)}
            />
        </>
    );
}

export default Subscription;
