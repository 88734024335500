export const loadScript = (src, id, async = true) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = async;
    
    script.onload = resolve;
    script.onerror = reject;
    
    document.head.appendChild(script);
  });
};

export const loadInlineScript = (content, id) => {
  if (document.getElementById(id)) {
    return;
  }

  const script = document.createElement('script');
  script.id = id;
  script.textContent = content;
  document.head.appendChild(script);
};

export const removeTrackingScripts = () => {
  // Script IDs to remove
  const scriptIdsToRemove = [
    'gtm-script',
    'gtag-script',
    'gtag-config',
    'meta-pixel-script'
  ];

  // Remove script elements
  scriptIdsToRemove.forEach(id => {
    const script = document.getElementById(id);
    if (script) {
      script.remove();
    }
  });

  // Remove Meta Pixel noscript element
  const noscriptElements = document.getElementsByTagName('noscript');
  Array.from(noscriptElements).forEach(element => {
    if (element.innerHTML.includes('facebook.com/tr')) {
      element.remove();
    }
  });
};
