import { Tooltip } from 'components/ui';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import { CompanyContext } from 'contexts/CompanyContext';
import React, { useContext } from 'react'
import MySocialIcon from 'components/shared/MySocialIcon';
import classNames from 'classnames';
import MediumIntegrationModal from 'components/layout/SocialIntegrationModals/MediumIntegrationModal';
import useDarkMode from 'utils/hooks/useDarkMode';
import { HiOutlineExclamationTriangle, HiOutlinePlus } from 'react-icons/hi2';
import { checkIfIsExpired } from 'utils/checkIfIsExpired';
import { checkIfIsIntegrated } from 'utils/checkIfIsIntegrated';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import LinkedinIntegrationModal from 'components/layout/SocialIntegrationModals/LinkedinIntegrationModal';
import TwitterIntegrationModal from 'components/layout/SocialIntegrationModals/TwitterIntegrationModal';
import InstagramIntegrationModal from 'components/layout/SocialIntegrationModals/InstagramIntegrationModal';
import FacebookIntegrationModal from 'components/layout/SocialIntegrationModals/FacebookIntegrationModal';
import TiktokIntegrationModal from 'components/layout/SocialIntegrationModals/TiktokIntegrationModal';
import PinterestIntegrationModal from 'components/layout/SocialIntegrationModals/PinterestIntegrationModal';
import YoutubeIntegrationModal from 'components/layout/SocialIntegrationModals/YoutubeIntegrationModal';
import { Trans } from 'react-i18next';
import capitalize from 'components/ui/utils/capitalize';

const API_URL = process.env.REACT_APP_API_HOST;

const SocialNetworkCircle = ({ socialNetwork, showOnlyIfIntegrated = false, isCircle = true, forceActive = false, forceOnClick = undefined, allowOnlyOne = false, isNotAllowed = false, className = "", innerClassName = "" }) => {

    const { calendarActiveSocialNetwork, setCalendarActiveSocialNetwork } = useContext(CalendarActiveSocialNetworkContext);
    const { company, companySocials } = useContext(CompanyContext);
    const { openModalPriority } = useContext(PriorityModalContext);
    const [isDark] = useDarkMode()

    let isActive = !isCircle ? forceActive : calendarActiveSocialNetwork?.includes(socialNetwork);
    const socialNetworkIntegrationObj = companySocials?.filter(e => e.type === socialNetwork)?.[0];
    const isIntegrated = checkIfIsIntegrated(socialNetwork, companySocials);
    const isExpired = isIntegrated && checkIfIsExpired(socialNetworkIntegrationObj);
    
    // if (!allowOnlyOne && !isIntegrated && !forceActive) {
    //     isActive = false;
    // }

    const profilePic = socialNetworkIntegrationObj ? socialNetworkIntegrationObj?.image?.url : "";

    const handleOnClickMultiple = () => {
        // if (isIntegrated && !isExpired && !isNotAllowed) {
            if (isActive) {
                setCalendarActiveSocialNetwork(
                    calendarActiveSocialNetwork.filter(function(item) {
                        return item !== socialNetwork
                    })
                );
            } else {
                setCalendarActiveSocialNetwork([...calendarActiveSocialNetwork, socialNetwork]);
            }
        // }
    }

    const handleOnClickOnlyOne = () => {
        // if (isIntegrated && !isExpired && !isNotAllowed) {
            // if (isActive) {
            //     setCalendarActiveSocialNetwork(
            //         calendarActiveSocialNetwork.filter(function(item) {
            //             return item !== socialNetwork
            //         })
            //     );
            // } else {
                setCalendarActiveSocialNetwork([socialNetwork]);
            // }
        // }
    }

    const handleIntegrate = () => {
        if (!isIntegrated || isExpired) {
            if (socialNetwork === "medium") { openModalPriority(<MediumIntegrationModal />, 700); }
            else if (socialNetwork === "linkedin") { openModalPriority(<LinkedinIntegrationModal />, 600) }
            else if (socialNetwork === "twitter") { openModalPriority(<TwitterIntegrationModal />, 500) }
            else if (socialNetwork === "instagram") { openModalPriority(<InstagramIntegrationModal />, 500) }
            else if (socialNetwork === "facebook") { openModalPriority(<FacebookIntegrationModal />, 500) }
            else if (socialNetwork === "tiktok") { openModalPriority(<TiktokIntegrationModal />, 500) }
            else if (socialNetwork === "pinterest") { openModalPriority(<PinterestIntegrationModal />, 500) }
            else if (socialNetwork === "youtube") { openModalPriority(<YoutubeIntegrationModal />, 500) }
            else {
                localStorage.setItem(`back_url`, window.location.pathname + window.location.search);
                window.location.href = `${API_URL}/companies/${company?.id}/socialNetworks/${socialNetwork}?token=${localStorage.getItem(`token`)}`;
            }
        }
    }

    const handleOnClick = () => {
        if (allowOnlyOne) {
            handleOnClickOnlyOne();
            return;
        }
        // if (!isIntegrated || isExpired) {
            // handleIntegrate()
        // } else {
            if (forceOnClick) {
                forceOnClick(socialNetwork);
            } else {
                if (allowOnlyOne) {
                    handleOnClickOnlyOne();
                } else {
                    handleOnClickMultiple();
                }
            }
        // }
    }

    if (!socialNetwork) return <></>;
    if (showOnlyIfIntegrated && !isIntegrated) return <></>;

    return (
        <Tooltip disabled={!isNotAllowed} title={isNotAllowed ? <Trans i18nKey="socialNetworkCircle.notAvailable">Due to <span className='capitalize'>{{socialNetwork: capitalize(socialNetwork) }}</span> limitations, this functionality is not available right now.</Trans> : undefined} wrapperClass={`block !shrink-0 ${className}`} placement='right'>
            <div 
                onClick={handleOnClick} 
                className={
                    classNames(
                        `${isCircle 
                            ? `rounded-full border` 
                            : `border-b`
                        }`,
                        `bg-white dark:bg-gray-800`,
                        `shrink-0`,
                        // `!aspect-square`,
                        `!w-[47px] !h-[47px]`,
                        `border-gray-200 dark:border-gray-600`,
                        `cursor-pointer`,
                        `transition-all`,
                        `relative`,
                        // `!h-full lg:h-inherit`,
                        `${isActive && 
                            `${isCircle 
                                ? `border-[2.5px]` 
                                : `!border-${socialNetwork} !border-b-[2px] !border-t-[2px] first:!border-t-[0px] last:!border-b-[0px] !bg-white dark:!bg-gray-700`
                            }` 
                        }`,
                        isActive && isCircle && socialNetwork === "twitter" && `!border-twitter`,
                        isActive && isCircle && socialNetwork === "facebook" && `!border-facebook`,
                        isActive && isCircle && socialNetwork === "linkedin" && `!border-linkedin`,
                        isActive && isCircle && socialNetwork === "discord" && `!border-discord`,
                        isActive && isCircle && socialNetwork === "pinterest" && `!border-pinterest`,
                        isActive && isCircle && socialNetwork === "tiktok" && `!border-tiktok`,
                        isActive && isCircle && socialNetwork === "medium" && `!border-medium`,
                        isActive && isCircle && socialNetwork === "instagram" && `!border-instagram`,
                        isActive && isCircle && socialNetwork === "youtube" && `!border-youtube`,
                        isIntegrated && !isActive && `grayscale opacity-[0.8] hover:!grayscale-0 hover:opacity-[0.9]`,
                        (!isIntegrated || isExpired) && `hover:!grayscale-0 hover:opacity-[0.9] hover:!bg-gray-100 hover:dark:!bg-gray-700`,
                        // isExpired && `outline outline-2 outline-red-800 !grayscale-0`,
                        isNotAllowed && `!cursor-not-allowed !opacity-[0.25] hover:!grayscale hover:!opacity-[0.25]`,
                        innerClassName
                    )
                } 
                style={{ backgroundImage: (isIntegrated && !isExpired) ? profilePic ? `url('${profilePic}')` : `url('/img/others/profile.png')` : undefined, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                {(!isIntegrated || isExpired) && (
                    <img src={`/img/socials/${socialNetwork}.svg`} className={`absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6 ${(!isIntegrated || isExpired) && !isActive ? `grayscale opacity-50` : ``} ${isCircle ? `` : ``}`} />
                )}
                {!isIntegrated && !allowOnlyOne && (
                    <Tooltip 
                        placement="right"
                        wrapperClass='absolute rounded-full bg-gray-800 text-white dark:bg-gray-900 dark:text-white p-1 z-10 -right-1 -bottom-1'
                        title={<Trans i18nKey="socialNetworkCircle.connectNetwork">Connect <span className='capitalize'>{{socialNetwork: capitalize(socialNetwork) }}</span></Trans>}
                    >
                        <div onClick={e => { e.stopPropagation(); handleIntegrate(); }}>
                            <HiOutlinePlus className='text-[13px]' />
                        </div>
                    </Tooltip>
                )}
                {isExpired && !allowOnlyOne && (
                    <Tooltip 
                        placement="right"
                        wrapperClass='z-[100] absolute rounded-full bg-red-600 text-white p-1 z-10 -right-1 -bottom-1'
                        title={<>Integration expired. Click to connect <span className='capitalize'>{socialNetwork}</span> again.</>}
                    >
                        <div onClick={e => { e.stopPropagation(); handleIntegrate(); }}>
                            <HiOutlineExclamationTriangle className='text-[13px]' />
                        </div>
                    </Tooltip>
                )}
                {isActive && isCircle && isIntegrated && !isExpired && (
                    <div className='absolute rounded-full text-white bg-primary-600 p-1 z-10 -right-1 -bottom-1'>
                        <MySocialIcon 
                            network={socialNetwork} 
                            className={`transition-all !h-6 !w-6`} 
                            wrapperClassName={`!absolute z-10 transition-all -right-1 -bottom-1 !w-fit`} 
                        />
                    </div>
                )}
                {isIntegrated && !isExpired && <>
                    <MySocialIcon 
                        network={socialNetwork} 
                        className={`transition-all ${isActive && `!h-6 !w-6`} ${`!h-full !w-full`}`} 
                        wrapperClassName={`!absolute z-10 transition-all ${isActive ? `-right-1.5 -bottom-1.5 !w-fit` : `!w-full !h-full`}`} 
                    />
                    <MySocialIcon 
                        network={socialNetwork} 
                        className={`transition-all ${isActive && `!h-6 !w-6`} ${`!h-full !w-full`}`} 
                        bgColor={isActive ? undefined : isDark ? `#666666` : `#888888`} 
                        wrapperClassName={`!absolute z-10 transition-all ${isActive ? `-right-1.5 -bottom-1.5 !w-fit` : `!w-full !h-full hover:!opacity-0`}`} 
                    />
                </>}

                {/* {isExpired && <>
                    <Tooltip title="Integration expired!" placement='right' wrapperClass='absolute left-0 top-0 w-full h-full bg-red-800/30 text-red-600 flex items-center justify-center z-10 rounded-full'>
                        <HiExclamationTriangle className='text-2xl drop-shadow' />
                    </Tooltip>
                </>} */}
            </div>
        </Tooltip>
    );
}

export default SocialNetworkCircle;
