import { Tooltip } from 'components/ui';
import { CompanyContext } from 'contexts/CompanyContext';
import React, { useContext, useEffect, useMemo } from 'react'
import { HiOutlineCog, HiOutlinePlus } from 'react-icons/hi';
import SocialNetworkCircle from './SocialNetworkCircle';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';
import { useNavigate } from 'react-router-dom';
import { getSocialNetworkIntegration } from 'utils/getSocialNetworkIntegration';
import { YOUTUBE_ENABLED } from 'components/ui/utils/constant';

export const socialNetworks = ["instagram", "facebook", "twitter", "linkedin", "tiktok", "medium", "pinterest", ...(YOUTUBE_ENABLED ? ["youtube"] : [])];
export const allNetworks = ["instagram", "facebook", "twitter", "linkedin", "tiktok", "medium", "pinterest", "canva", ...(YOUTUBE_ENABLED ? ["youtube"] : [])];

export const socialNetworksMediaProductTypes = {
    "instagram": ["FEED", "STORY", "REEL"],
    "facebook": ["FEED", "STORY", "REEL"],
    "twitter": ["FEED"],
    "linkedin": ["FEED"],
    "tiktok": ["FEED"],
    "medium": ["FEED"],
    "pinterest": ["FEED"],
    "youtube": ["FEED", "SHORT"]
}

const SocialNetworkSidePanel = ({ className = "", innerClassName = "", itemClassName = "", showPlus = true, showOnlyIntegrated = false, isCircle = true, allowOnlyOne = false, allowedSocialNetworks = socialNetworks, showSelectAll = true }) => {
    
    const { calendarActiveSocialNetwork, setCalendarActiveSocialNetwork } = useContext(CalendarActiveSocialNetworkContext)
    const { setCompany, companySocials, company } = useContext(CompanyContext);
    const navigate = useNavigate();

    const allSelected = useMemo(() => {
        return calendarActiveSocialNetwork?.length === allowedSocialNetworks?.length;
    }, [calendarActiveSocialNetwork, allowedSocialNetworks]);

    const addCompany = () => {
        setCompany(undefined);
    }

    const selectAll = () => {
        setCalendarActiveSocialNetwork(allowedSocialNetworks);
    }

    const unselectAll = () => {
        setCalendarActiveSocialNetwork([]);
    }

    useEffect(() => {
        if (
            allowedSocialNetworks && 
            allowedSocialNetworks?.length > 0 && 
            calendarActiveSocialNetwork.filter(network => !allowedSocialNetworks?.includes(network))?.length > 0
        ) {
            if (allowOnlyOne) {
                setCalendarActiveSocialNetwork([calendarActiveSocialNetwork.filter(network => allowedSocialNetworks?.includes(network))?.[0]])
            } else {
                setCalendarActiveSocialNetwork(calendarActiveSocialNetwork.filter(network => allowedSocialNetworks?.includes(network))); 
            }
        }  
    }, [calendarActiveSocialNetwork]);

    useEffect(() => {
        if (allowOnlyOne) {
            if (calendarActiveSocialNetwork?.length > 0) {
                setCalendarActiveSocialNetwork([calendarActiveSocialNetwork[0]]); 
            } else {
                setCalendarActiveSocialNetwork([socialNetworks[0]]); 
            }
        }
    }, [allowOnlyOne]);
    
    return (
        <div className={`shrink-0 h-auto lg:h-full ${isCircle ? `border-b lg:border-b-0 lg:border-r` : ``} border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 px-4 py-3 lg:py-4 lg:w-[80px] flex flex-row lg:flex-col gap-2 lg:gap-4 justify-between overflow-y-auto ${className}`}>
            <div className={`flex flex-row lg:flex-col lg:gap-4 gap-2 ${innerClassName}`}>
                {socialNetworks.sort((a, b) => +allowedSocialNetworks?.includes(b) - +allowedSocialNetworks?.includes(a)).sort((a, b) => +(!getSocialNetworkIntegration(a, companySocials)) - +(!getSocialNetworkIntegration(b, companySocials))).map((socialNetwork, key) => (
                    <SocialNetworkCircle 
                        key={key} 
                        socialNetwork={socialNetwork} 
                        showOnlyIfIntegrated={showOnlyIntegrated}
                        isCircle={isCircle}
                        allowOnlyOne={allowOnlyOne}
                        isNotAllowed={allowedSocialNetworks && allowedSocialNetworks?.length > 0 && !allowedSocialNetworks?.includes(socialNetwork)}
                        className={itemClassName}
                        innerClassName={`social-network-index-${key} social-network-${socialNetwork}`}
                    />
                ))}
                {!allowOnlyOne && !!showSelectAll && <>
                    {allSelected 
                        ? <span onClick={unselectAll} className='flex justify-center items-center border-l border-r lg:border-r-0 lg:border-l-0 lg:border-t border-gray-200 dark:border-gray-700 text-center text-xs pr-3 lg:pr-0 pl-3 lg:pl-0 lg:pt-3 ml-2 mr-2 lg:mr-0 lg:ml-0 lg:mt-1 hover:text-primary-600 cursor-pointer lg:!w-[47px] !h-[47px]'>
                            Unselect All
                        </span>
                        : <span onClick={selectAll} className='flex justify-center items-center border-l border-r lg:border-r-0 lg:border-l-0 lg:border-t border-gray-200 dark:border-gray-700 text-center text-xs pr-3 lg:pr-0 pl-3 lg:pl-0 lg:pt-3 ml-2 mr-2 lg:mr-0 lg:ml-0 lg:mt-1 hover:text-primary-600 cursor-pointer lg:!w-[47px] !h-[47px]'>
                            Select All
                        </span>
                    }
                </>}
            </div>
            {showPlus && 
                <div className="flex flex-row lg:flex-col lg:gap-4 gap-2">
                    {company && 
                        <Tooltip title="Company integration settings" wrapperClass="shrink-0 block h-full lg:h-auto !w-[47px] !h-[47px]" placement='right'>
                            <div onClick={() => navigate(`/company-settings/integrations`)} className={`rounded-full aspect-square border border-gray-200 dark:border-gray-600 cursor-pointer relative overflow-hidden h-full lg:h-auto !w-[47px] !h-[47px]`}>
                                <HiOutlineCog className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6 text-black dark:text-white" />
                            </div>
                        </Tooltip>
                    }
                    <Tooltip title="Create new companny" wrapperClass="shrink-0 block h-full lg:h-auto !w-[47px] !h-[47px]" placement='right'>
                        <div onClick={() => addCompany()} className={`rounded-full aspect-square border border-gray-200 dark:border-gray-600 cursor-pointer relative overflow-hidden h-full lg:h-auto !w-[47px] !h-[47px]`}>
                            <HiOutlinePlus className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6 text-black dark:text-white" />
                        </div>
                    </Tooltip>
                </div>
            }
        </div>
    );
}

export default SocialNetworkSidePanel
