import { CompanyContext } from "contexts/CompanyContext";
import { AIContext } from "contexts/AIContext";
import { useContext } from "react"
import usePostValidator from "./usePostValidator";
import { getImageAICaptionURL } from "utils/getImageAICaption";
import { CompanyPromptsContext } from "contexts/CompanyPromptsContext";
import { stripHTMLReplaceWithNewLines } from "utils/stripHTML";
import { stripMarkdown } from "utils/stripMarkdown";
import { INSTAGRAM_PROMPTS } from "configs/prompts/instagram";

export const socialNetworkFullPrompts = [
  ...INSTAGRAM_PROMPTS.map(prompt => ({ type: `instagram`, prompt })),

  { type: `facebook`, prompt: `Write a very short Facebook post using between 100 and 150 characters. Add a CTA in a separate paragraph at the end.` },
  { type: `facebook`, prompt: `Write a Facebook post use between 200 and 260 characters. Add a CTA to encourage followers to comment on the post.` },
  // { type: `facebook`, prompt: `Write a Facebook post to encourage followers to participate in a survey. Use bullets to list the survey questions and add a CTA prompting users to send you a direct message within 240-280 characters.` },
  // { type: `facebook`, prompt: `Write an engaging Facebook post prompting audience interaction. Write using separate paragraphs. Pose a question and encourage creative responses within 240-280 characters.` },
  // { type: `facebook`, prompt: `Write industry insights or tips in a concise Facebook post. Use bullet points to organize key points and encourage engagement within 220-250 characters.` },
  // { type: `facebook`, prompt: `Write a Facebook post to invite followers to share their experiences or testimonials. Use a clear CTA to foster community engagement within 200-240 characters.` },
  // { type: `facebook`, prompt: `Write a Facebook post introducing your service with humor or creativity. Include a CTA to visit your website and engage viewers within 200-240 characters.` },
  // { type: `facebook`, prompt: `Write an engaging Facebook post with quick tips or hacks related to your brand. Use bullets to outline details and encourage further exploration. Encourage viewers to try the hack and provide feedback within 240-280 characters.` },

  { type: `twitter`, prompt: `Write a very short Twitter post using between 100 and 150 characters. You must use less than 150 characters.` },
  { type: `twitter`, prompt: `Write a post copy using between 160 and 220 characters. Encourage followers to comment on the post. Do not use emojis. You must use less than 220 characters.` },
  { type: `twitter`, prompt: `Write an 8-tweet thread using between 120 and 220 characters per tweet. The first tweet must be intriguing. The last tweet must have a CTA to follow us on twitter` },
  // { type: `twitter`, prompt: `Write a Twitter post to encourage followers to participate in a survey using between 200 and 280 characters. Use bullets to list the survey questions and add a CTA prompting users to send you a direct message.` },
  // { type: `twitter`, prompt: `Write an engaging Twitter post prompting audience interaction using between 200 and 280 characters. Write using separate paragraphs. Pose a question and encourage creative responses.` },
  // { type: `twitter`, prompt: `Write industry insights or tips in a concise Twitter post, between 150 and 250 characters. Use bullet points to organize key points and encourage engagement. Do not use emojis.` },
  // { type: `twitter`, prompt: `Write a Twitter post within 200-280 characters to invite followers to share their experiences or testimonials. Use a clear CTA to foster community engagement.` },
  // { type: `twitter`, prompt: `Write a Twitter post introducing your service with humor or creativity using between 200 and 240 characters. Include a CTA to visit your website and engage viewers. Do not use emojis.` },
  // { type: `twitter`, prompt: `Write an engaging Twitter post with quick tips or hacks related to your brand. Use bullets to outline details and encourage further exploration. Encourage viewers to try the hack and provide feedback. Use 240-280 characters. Do not use emojis.` },

  { type: `linkedin`, prompt: `Write a very short LinkedIn post using between 100 and 150 characters. Add a CTA in a separate paragraph at the end.` },
  { type: `linkedin`, prompt: `Write a LinkedIn post use between 200 and 260 characters. Add a CTA to encourage followers to comment on the post.` },
  // { type: `linkedin`, prompt: `Write a LinkedIn post to encourage followers to participate in a survey. Use bullets to list the survey questions and add a CTA prompting users to send you a direct message within 240-280 characters.` },
  // { type: `linkedin`, prompt: `Write an engaging LinkedIn post prompting audience interaction. Write using separate paragraphs. Pose a question and encourage creative responses within 240-280 characters.` },
  // { type: `linkedin`, prompt: `Write industry insights or tips in a concise LinkedIn post. Use bullet points to organize key points and encourage engagement within 220-250 characters.` },
  // { type: `linkedin`, prompt: `Write a LinkedIn post to invite followers to share their experiences or testimonials. Use a clear CTA to foster community engagement within 200-240 characters.` },
  // { type: `linkedin`, prompt: `Write a LinkedIn post introducing your service with humor or creativity. Include a CTA to visit your website and engage viewers within 200-240 characters.` },
  // { type: `linkedin`, prompt: `Write an engaging LinkedIn post with quick tips or hacks related to your brand. Use bullets to outline details and encourage further exploration. Encourage viewers to try the hack and provide feedback within 240-280 characters.` },

  { type: `tiktok`, prompt: `Write a very short TikTok caption using between 100 and 150 characters. Add a CTA in a separate paragraph at the end.` },
  { type: `tiktok`, prompt: `Write a TikTok caption using less than 200 characters. Add a CTA to encourage followers to comment on the post.` },
  // { type: `tiktok`, prompt: `Write a TikTok caption to encourage followers to participate in a survey. Use bullets to list the survey questions and add a CTA prompting users to send you a direct message within 240-280 characters.` },
  // { type: `tiktok`, prompt: `Write an engaging TikTok caption prompting audience interaction. Write using separate paragraphs. Pose a question and encourage creative responses within 240-280 characters.` },
  // { type: `tiktok`, prompt: `Write industry insights or tips in a concise TikTok caption. Use bullet points to organize key points and encourage engagement within 220-250 characters.` },
  // { type: `tiktok`, prompt: `Write a TikTok caption to invite followers to share their experiences or testimonials. Use a clear CTA to foster community engagement within 200-240 characters.` },
  // { type: `tiktok`, prompt: `Write a TikTok caption introducing your service with humor or creativity. Include a CTA to visit your website and engage viewers within 200-240 characters.` },
  // { type: `tiktok`, prompt: `Write an engaging TikTok caption with quick tips or hacks related to your brand. Use bullets to outline details and encourage further exploration. Encourage viewers to try the hack and provide feedback within 240-280 characters.` },

  { type: "medium", prompt: `Write a blog post using between 5 to 8 paragraphs.` },
  { type: "medium", prompt: `Write a blog post using between 8 to 10 paragraphs.` },
  { type: "medium", prompt: `Write a blog post using between 5 to 8 paragraphs. Add a last paragraph to encourage readers to visit the website. Relate the blog topic with the company in the last paragraphs.` },

  { type: `pinterest`, prompt: `Write a very short Pinterest post using between 100 and 150 characters. Add a CTA in a separate paragraph at the end.` },
  { type: `pinterest`, prompt: `Write a Pinterest post use between 200 and 260 characters. Add a CTA to encourage followers to comment on the post.` },
  // { type: `pinterest`, prompt: `Write a Pinterest post to encourage followers to participate in a survey. Use bullets to list the survey questions and add a CTA prompting users to send you a direct message within 240-280 characters.` },
  // { type: `pinterest`, prompt: `Write an engaging Pinterest post prompting audience interaction. Write using separate paragraphs. Pose a question and encourage creative responses within 240-280 characters.` },
  // { type: `pinterest`, prompt: `Write industry insights or tips in a concise Pinterest post. Use bullet points to organize key points and encourage engagement within 220-250 characters.` },
  // { type: `pinterest`, prompt: `Write a Pinterest post to invite followers to share their experiences or testimonials. Use a clear CTA to foster community engagement within 200-240 characters.` },
  // { type: `pinterest`, prompt: `Write a Pinterest post introducing your service with humor or creativity. Include a CTA to visit your website and engage viewers within 200-240 characters.` },
  // { type: `pinterest`, prompt: `Write an engaging Pinterest post with quick tips or hacks related to your brand. Use bullets to outline details and encourage further exploration. Encourage viewers to try the hack and provide feedback within 240-280 characters.` },
  
  { type: `youtube`, prompt: `Write a YouTube video description. The descriptions should not be longer than 1000 characters long. The description should be long and contain multiple informational paragraphs about the topic. The description should also ask the viewer to click the subscribe button.` },
  { type: `youtube`, prompt: `Write a YouTube video description that's engaging and SEO-friendly. Include 3-4 paragraphs about the topic, add relevant keywords naturally, and include a products/resources section if applicable. End with social media links and a subscribe reminder. Keep under 1000 characters.` },
  { type: `youtube`, prompt: `Write a YouTube video description that starts with a hook, includes key points in bullet format, adds relevant links, and ends with a question to encourage comments. Keep under 900 characters.` },
  // { type: `youtube`, prompt: `Write a YouTube video description using between 500-800 characters. Include timestamps for key moments in brackets [00:00]. Add relevant hashtags at the end. Include links to social media profiles and a clear call-to-action to subscribe.` },
];

// // export const socialNetworkFullPrompts = [
// //   {
// //     type: "instagram", 
// //     prompt: `Write a very short Instagram post using between 100 and 200 characters. Add a short call to action in a separate paragraph at the end to take users to our website. Use a funny sarcastic communication tone.`,
// //   },
// //   {
// //     type: "facebook", 
// //     prompt: `Write a Facebook post using between 150 and 1200 characters.`,
// //   },
// //   {
// //     type: "twitter", 
// //     prompt: `Write a Twitter post using between 120 and 280 characters. It must be shorter than 280 characters.`,
// //   },
// //   {
// //     type: "linkedin", 
// //     prompt: `Write a LinkedIn post using between 250 and 1200 characters.`,
// //   },
// //   {
// //     type: "tiktok", 
// //     prompt: `Write a Tiktok caption using between 25 and 250 characters.`,
// //   },
// //   {
// //     type: "medium", 
// //     prompt: `Write a blog post.`,
// //   },
// //   {
// //     type: "pinterest", 
// //     prompt: `Write a Pinterest post using between 25 and 250 characters.`,
// //   }
// ]

export const useCopyGenerator = () => {

  const { getByQueryChat } = useContext(AIContext);
  const { validationConstants } = usePostValidator();
  const { company } = useContext(CompanyContext);
  const { prompts, defaultPrompts } = useContext(CompanyPromptsContext);

  const formatContextSystemMessage = (context) => {
    return `POST CONTEXT:
"${context}"
`;
  }

  const formatContextPromptCopy = (network, context, extraInstructions = "", imageURLs = [], promptIndexes = []) => {

    let _prompts = prompts;
    _prompts = _prompts.filter((e, i) => promptIndexes.includes(i));
    if (!_prompts?.length) _prompts = defaultPrompts;
    _prompts = _prompts.filter(e => e.type == network);
    const prompt = _prompts.random().prompt;

    let finalPrompt = `

${!!extraInstructions 
  ? `${extraInstructions}` 
  : ``
}

${!!imageURLs?.length && network == "medium" 
  ? `You can include the following images in <img /> tags:
${imageURLs?.map((url) => `${url}\n`)}` 
  : ``}

${network == "medium" 
  ? `Write in HTML using ONLY the following tags if needed: h1, h2, p, blockquote, b, strong, i, em, a, hr, br. Do not use <style> tag.`
  : ``
}

The post must be related to the POST CONTEXT.

Write in ${company?.language}.

${prompt}.

Skip the preamble.
`;
  return finalPrompt.trim();
  }

  const formatContextPromptTitle = (network, context, extraInstructions = "") => {
    return `

      POST CONTEXT:
      ${stripHTMLReplaceWithNewLines(context)}

      INSTRUCTIONS:
      - Max ${validationConstants?.[`${network}_feed`]?.title?.max_length} characters

      ${!!extraInstructions ? `
        EXTRA INSTRUCTIONS:
        ${extraInstructions}
      ` : ``}

      Write a title given the POST CONTEXT. Write in ${company?.language}.

      Title: 
    `;
  }

  const generateMultipleCopiesFromCopy = async (copy) => {
    const query = `
      Given the following social media post copy: ${copy}.
      Split it into multiple parts with similar length so it can be posted as a sequence. Every tweet must be somehow related to the previous tweet so it makes sense. The first tweet must intrigue the reader to read the second one, and so on... 
      Separate each part with "---" so I can split it programmatically.

      Keep the same tone, language and CTA.

      No preambles.
    `;
    copy = await getByQueryChat(query, false);
    copy = formatCopy(copy);
    let copies = copy?.split("---");
    copies = copies.map(c => formatCopy(c));
    return copies;
  }

  const formatCopy = (copy) => {
    copy = copy?.trim();
    if (copy.indexOf("Here is a") === 0) copy = copy.substring(copy.indexOf(":") + 1);
    if (copy.indexOf("Here's a") === 0) copy = copy.substring(copy.indexOf(":") + 1);
    copy = copy?.trim();
    return copy.replaceAll("\"", "");
  }

  const generateCopyFromAssets = async (network, assets, extraInstructions = "") => {
    let contexts = await Promise.allSettled(assets.map((asset) => getImageAICaptionURL(asset?.image?.thumbnail?.url || asset?.image?.url))); 
    contexts = contexts.filter(e => !!e.value).map((e, key) => `Image ${key+1}: ${e.value}`);
    const context = contexts?.join("\n");
    const system_query = formatContextSystemMessage(context);
    const query = formatContextPromptCopy(network, context, extraInstructions);
    const copy = await getByQueryChat(query, true, false, system_query, undefined, { temperature: 1, top_p: 1 });
    return formatCopy(copy);
  }

  const generateTitleFromAssets = async (network, assets, extraInstructions = "") => {
    let contexts = await Promise.allSettled(assets.map((asset) => getImageAICaptionURL(asset?.image?.thumbnail?.url || asset?.image?.url))); 
    contexts = contexts.filter(e => !!e.value).map((e, key) => `Image ${key+1}: ${e.value}`);
    const query = formatContextPromptTitle(network, contexts?.join("\n"), extraInstructions);
    const copy = await getByQueryChat(query, true);
    return formatCopy(copy);
  }

  const generateCopyFromContext = async (network, context, extraInstructions = "", imageURLs = [], promptIndexes = []) => {
    const system_query = formatContextSystemMessage(context);
    const query = formatContextPromptCopy(network, context, extraInstructions, imageURLs, promptIndexes);
    const copy = await getByQueryChat(query, true, false, system_query, undefined, { temperature: 1, top_p: 1 });
    return formatCopy(copy);
  }

  const generateTitleFromContext = async (network, context, extraInstructions = "") => {
    const query = formatContextPromptTitle(network, context, extraInstructions);
    const copy = await getByQueryChat(query, true);
    return formatCopy(copy);
  }

  return { 
    generateCopyFromContext, 
    generateTitleFromContext, 
    generateCopyFromAssets, 
    generateTitleFromAssets,
    generateMultipleCopiesFromCopy
  }
}

export default useCopyGenerator;